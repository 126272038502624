import { Box, CircularProgress, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { useWorkedVsExpectedHours } from "../utils/useWorkVsExpectedHours";

type WorkHoursSummaryProps = {
  isAdminOrCurrentUser: boolean;
  isServiceProvider: boolean;
};

export const WorkHoursSummary = ({ isAdminOrCurrentUser, isServiceProvider }: WorkHoursSummaryProps) => {
  const {
    selectedYear,
    selectedMonth,
    totals: { effectiveWorkHours, totalWorkHours, totalHolidays, totalSickLeaveDays },
    thisYearHolidays,
    staffAgreement: { value_per_hour },
    userId,
  } = useContext(StaffWorkLogsContext);

  const totalValue = value_per_hour ? effectiveWorkHours * value_per_hour : null;

  const date = new Date(selectedYear, selectedMonth);
  const { data, status: expectedWorkingHoursStatus } = useWorkedVsExpectedHours(date, userId, thisYearHolidays);
  if (expectedWorkingHoursStatus === "loading") return <CircularProgress />;
  
  // Im including the holidays in the total worked hours so we can see if we are passing max amount of hours per month
  const totalWorkHoursWithUnvavailabilities  = totalWorkHours + (totalHolidays + totalSickLeaveDays) * 8;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography variant="h6" color="primary">
          Horas trabalhadas Mês
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="body1">
            {totalWorkHoursWithUnvavailabilities.toFixed(2)} {!isServiceProvider && `/ ${data?.monthlyExpectedHours.toFixed(2)}`}
          </Typography>
          {isAdminOrCurrentUser && isServiceProvider && (
            <Typography>Valor a receber: {totalValue && formatCurrency(totalValue)}</Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Typography variant="h6" color="primary">
          Horas trabalhadas Ano
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="body1">
            {data?.hoursWorkedUpToCurrentMonth.toFixed(2)}{" "}
            {!isServiceProvider && `/ ${data?.hoursExpectedUpToCurrentMonth.toFixed(2)}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
