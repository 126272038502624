import { Box, Card, CardContent, Typography, List, ListItem, ListItemText, Skeleton, CardMedia, Paper } from "@mui/material";
import { useRecords } from "../api/useRecords";
import { useImage } from "../api/useImage";
import { Process, ProcessParentCategory } from "./types";
import { useNavigate } from "react-router-dom";
import { ProcessesTypeIcon } from "./components/ProcessesTypeIcon";

export const ProcessesHome = () => {
    const navigate = useNavigate();
    const { data: processes, status: processesStatus } = useRecords("processes", "*.*");
    const { data: processParentCategories, status: processParentCategoriesStatus } = useRecords(
        "processes_parent_category",
        "*.*.*"
    );

    if (processesStatus === "loading" || processParentCategoriesStatus === "loading") {
        return <Skeleton variant="rectangular" height={400} />;
    }

    if (!processes || !processParentCategories) {
        return null;
    }

    return (
        <Paper sx={{ p: 3, height: "100vh" }}>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Biblioteca de processos e conhecimento.
            </Typography>

            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", gap: 2, mb: 4 }}>
                {processParentCategories.items.map((category: ProcessParentCategory) => (
                    <Card
                        key={category.id}
                        sx={{
                            bgcolor: "background.paper",
                            cursor: "pointer",
                            "&:hover": {
                                opacity: 0.9,
                            },
                        }}
                        onClick={() => navigate(`/processes/parentcategory/${category.id}`)}
                    >
                        <Image imgurl={category.picture?.id} />
                        <CardContent sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                            <Typography variant="h6" component="div">
                                {category.name}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                {Array.from(
                                    new Set(
                                        category.categories.flatMap((category) =>
                                            category.processes.map((process) => process?.type)
                                        )
                                    )
                                ).map((type) => {
                                    return <ProcessesTypeIcon key={`${category.id}-${type}`} type={type} size="small" />;
                                })}
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Paper>
    );
};

const Image = ({ imgurl }: { imgurl: string }) => {
    const { data } = useImage(imgurl, true);

    if (!data) return null;

    return <CardMedia component="img" image={data} height="194" />;
};
