import { getItems } from "../../../api/items";
import { getStore } from "../../../store/store";
import { AssociateItemFunction } from "../../../types/associate-record.types";

export const associatePartnerToTreatment: AssociateItemFunction = async (
    data,
    recordContext,
    relatedRecordsContext,
    { createRecord }
) => {
    const { notifications } = getStore();
    if (!recordContext || !relatedRecordsContext || !createRecord) return;

    const parentEntity = recordContext.entity;
    const parentId = recordContext.id;
    const entity = relatedRecordsContext.entity;
    const selectedItemId = data.professional_partner.id;

    const entityToUse = `${entity}_${parentEntity}`;
    const response = await getItems(entityToUse, "*.*", {
        filter: {
            _and: [
                {
                    [`${entity}_id`]: selectedItemId,
                },
                {
                    [`${parentEntity}_id`]: parentId,
                },
            ],
        },
        sort: "id",
    });

    if (response.items.length > 0) {
        notifications.setNotification({
            isOpen: true,
            message: `Registo já está associado`,
            severity: "error",
        });
        return;
    }

    try {
        await createRecord.mutateAsync({
            entity: entityToUse,
            data: {
                [`${entity}_id`]: selectedItemId,
                [`${parentEntity}_id`]: parentId,
            },
        });

        notifications.setNotification({
            isOpen: true,
            message: `Parceiro associado com sucesso`,
            severity: "success",
        });
    } catch (e) {
        notifications.setNotification({
            isOpen: true,
            message: `Erro ao associar parceiro`,
            severity: "error",
        });
    }
};
