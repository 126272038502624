import { api } from "../../api/config";
import { RecordContextType } from "../../components/record/Record";
import { queryClient } from "../../main";
import { getStore } from "../../store/store";
import { calculateApiItemsTotal } from "./utils";

export const actions = [
    {
        code: "create_invoice",
        name: "Criar Fatura",
        actionFn: (context: any, executeAction: any) => createInvoice(context),
    },
    {
        code: "create_invoice_without_nif",
        name: "Criar Fatura (SEM NIF)",
        actionFn: (context: any, executeAction: any) => createInvoice(context, true),
    },
    {
        code: "get_invoice",
        name: "Ver fatura",
        actionFn: (context: any, executeAction: any) => getInvoice(context),
    },
    {
        code: "associate_invoice_manually",
        name: "Associar fatura manualmente",
        actionFn: (context: any, executeAction: any) => associateInvoiceManually(context),
    },
];

const createInvoice = async (context: RecordContextType, withoutNif?: boolean) => {
    const confirmed = window.confirm("De certeza que deseja criar uma fatura para esta compra?");
    if (!confirmed) return;

    const { notifications } = getStore();

    if (!withoutNif) {
        const missingFields = areAllRequiredFieldsFilled(context);
        if (missingFields) {
            notifications.setNotification({
                message: missingFields,
                isOpen: true,
                severity: "error",
            });
            return;
        }
    }

    const orderTotalAmount = calculateApiItemsTotal(context.item.order_items);

    const orderData = {
        ...context.item,
        total: orderTotalAmount,
    };

    try {
        await api({
            method: "POST",
            url: "https://hook.eu1.make.com/km0d1rbb97nm1jvocargtfpqyxbkmd8l",
            data: {
                withoutNif: withoutNif,
                order: orderData,
            },
        });

        queryClient.invalidateQueries({ queryKey: [`orders-${context.item.id}`] });

        notifications.setNotification({
            message: `Fatura criada com sucesso e enviada para o email do cliente`,
            isOpen: true,
            severity: "success",
        });
    } catch (error: any) {
        notifications.setNotification({
            message: "Erro ao criar fatura",
            isOpen: true,
            severity: "error",
        });
        throw new Error(error.response.data);
    }
};

const getInvoice = async (context: RecordContextType) => {
    const { notifications } = getStore();
    try {
        const response = await api({
            method: "POST",
            url: "https://hook.eu1.make.com/ybgubakz5ybmhw9hdezm44duy4vh2b6l",
            data: context.item,
        });
        const invoiceUrl = response.data;
        window.open(invoiceUrl, "_blank");
    } catch (error: any) {
        notifications.setNotification({
            message: "Erro ao criar fatura",
            isOpen: true,
            severity: "error",
        });
        throw new Error(error.response.data);
    }
};

const associateInvoiceManually = async (context: RecordContextType) => {
    const { order } = getStore();
    order.setContext(context);
    order.setIsOpen(true);
};

const areAllRequiredFieldsFilled = (context: RecordContextType) => {
    const requiredFields = [
        { field: "vat", label: "NIF" },
        { field: "invoice_email", label: "Email de faturação" },
    ];
    const client = context.item.treatment.client;

    const missingFields = requiredFields
        .filter((field) => {
            return !client[field.field];
        })
        .map((field) => field.label);

    if (missingFields.length > 0) {
        const missingFieldsMessage = `Os seguintes campos são obrigatórios: ${missingFields.join(", ")}`;
        return missingFieldsMessage;
    }

    return null;
};
