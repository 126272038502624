import { useContext, useEffect, useState } from "react";
import { RecordsContext } from "../records/Records";
import { Box, Button, Unstable_Grid2 as Grid, IconButton } from "@mui/material";
import { useForm, useFieldArray, UseFormReturn, ErrorOption } from "react-hook-form";
import { Delete } from "@mui/icons-material";
import { FilterOperator } from "./FilterOperator";
import { FilterFieldValue } from "./FilterFieldValue";
import { FilterField } from "./FilterField";
import { FilterType } from "./FilterType";
import { useStore } from "../../store/store";
import { FormatedField } from "../../types/fields.types";
import { isEmpty, isArray } from "lodash";

type FilterCondition = "_and" | "_or";
export type WatchedFieldsType = {
    [x: string]: any;
};

type FilterProps = {
    setFilterIsOpen: (value: boolean) => void;
};

export const Filters = ({ setFilterIsOpen }: FilterProps) => {
    const context = useContext(RecordsContext);
    const [recordsFilter, setRecordsFilter, clearEntityFilters, modalFilter, setModalFilters] = useStore((state) => [
        state.recordsFilter || state.gridSettings.selectedSettings?.config?.filters?.records,
        state.setRecordsFilter,
        state.clearEntityFilters,
        state.modalFilter,
        state.setModalFilters,
    ]);

    const filtersFromApi = context?.entity && recordsFilter ? transformFilter(recordsFilter?.[context?.entity]) : null;

    const [filterType, setFilterType] = useState<FilterCondition>(filtersFromApi?.filterType || "_and");
    const entityForm = useForm() as unknown as UseFormReturn;

    const { fields, append, remove } = useFieldArray({
        name: "filters",
        control: entityForm.control,
    });

    useEffect(() => {
        if (modalFilter) {
            entityForm.setValue("filters", modalFilter);
        }
    }, [entityForm, modalFilter]);

    const onSubmit = (data: any) => {
        if (!validateFilters(data.filters)) return;

        const apiFilters = createApiFilters(filterType, data.filters);

        if (!context?.entity) return;
        setModalFilters(data.filters);

        setRecordsFilter({ ...recordsFilter, [context.entity]: apiFilters });
        setFilterIsOpen(false);
    };

    let formErrors: any[] = [];
    if (entityForm?.formState?.errors?.filters) {
        formErrors = entityForm?.formState?.errors?.filters as unknown as ErrorOption[];
    }

    if (!context) return null;

    return (
        <form id="filters-form" onSubmit={entityForm.handleSubmit(onSubmit)} style={{ marginTop: 10 }}>
            <Box mb={2} maxWidth={400}>
                <FilterType filterType={filterType} setFilterType={setFilterType} />
            </Box>
            <Grid container xs={12} alignItems="center" spacing={2} sx={{ mb: 2 }}>
                {fields.map((field, index) => {
                    return (
                        <Grid key={field.id} container xs={12} spacing={5}>
                            <Grid xs={4}>
                                <FilterField index={index} entityForm={entityForm} formErrors={formErrors} context={context} />
                            </Grid>
                            <Grid xs={3}>
                                {context && (
                                    <FilterOperator
                                        context={context}
                                        entityForm={entityForm}
                                        index={index}
                                        formErrors={formErrors}
                                    />
                                )}
                            </Grid>
                            <Grid xs={4}>
                                {context && <FilterFieldValue context={context} index={index} entityForm={entityForm} />}
                            </Grid>
                            <Grid xs={1} sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                                <IconButton
                                    sx={{ mt: 2 }}
                                    type="button"
                                    onClick={() => {
                                        remove(index);
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}

                <Grid>
                    <Button
                        type="button"
                        onClick={() =>
                            append({
                                field: "",
                                operator: "",
                            })
                        }
                    >
                        Adicionar Filtro
                    </Button>
                </Grid>
                <Grid>
                    <Button
                        onClick={() => {
                            remove();
                            clearEntityFilters(context.entity);
                        }}
                        color="secondary"
                    >
                        Limpar Filtros
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export type Filter = { field: FormatedField; operator: string; value: any };

const sanitizeValue = (value: any, field: FormatedField) => {
    if (value && value.id) return value.id;

    return value;
};

const handleManyToManyFields = (filter: Filter) => {
    const field = filter.field;
    const operator = filter.operator;
    const value = filter.value.map((value: { id: number; label: string }) => value.id);

    return {
        [field.field]: {
            [`${field.settings.relatedTable}_id`]: {
                id: {
                    [operator]: value,
                },
            },
        },
    };
};

const handleOtherFields = (filter: Filter) => {
    const fieldName = filter.field.field;

    return {
        [fieldName]: {
            [filter.operator]: sanitizeValue(filter.value, filter.field),
        },
    };
};

const createApiFilters = (filterType: string, filters: Array<Filter>) => {
    const apiFilters = {
        [filterType]: filters.map((filter: any) => {
            if (filter.field.settings.interface === "list-m2m" || filter.field.settings.type === "manytoany") {
                return handleManyToManyFields(filter);
            }

            return handleOtherFields(filter);
        }),
    };

    return apiFilters;
};

function transformFilter(input: { [x: string]: any } | undefined) {
    if (!input) return { filters: [], filterType: "_and" as FilterCondition };
    let filterType = Object.keys(input)[0] as FilterCondition; // get the "_and" key or equivalent
    let filterData = input[filterType]; // get the array containing the filters

    let filters = filterData.map((filter: any) => {
        let field = Object.keys(filter)[0]; // get the field name (e.g. "first_name")
        let operator = Object.keys(filter[field])[0]; // get the operator (e.g. "_icontains")
        let value = filter[field][operator]; // get the value (e.g. "pedro")

        return { field, operator, value };
    });

    return { filterType, filters };
}

function validateFilters(filters: Array<Filter>) {
    if (filters.length === 0) {
        alert("Por favor selecione campos para filtrar");
        return;
    }

    for (let filter of filters) {
        if (!filter.field || isEmpty(filter.field)) {
            alert(`Por favor selecione pelo menos um campo`);
            return false;
        }

        if (!filter.operator || isEmpty(filter.operator)) {
            alert(`Por favor selecione um operador ${filter.field.headerName}`);
            return false;
        }
        if (
            filter.operator !== "_empty" &&
            filter.operator !== "_null"
        ) {
            if (filter.value === null || filter.value === "" || (isArray(filter.value) && isEmpty(filter.value))) {
                alert(`A opção não pode estar vazia: ${filter.field.headerName}`);
                return false;
            }
        }
    }
    return true;
}
