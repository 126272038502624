import { UseRecordsOptions } from "../../api/useRecords";
import { leadsSearchSettings } from "../../utils/createSearchFilter";

export const clientsOptions = {
    fieldsToRemove: {
        fieldTypesToRemove: ["alias"],
        fieldNamesToRemove: ["id"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "progress",
                hidden: {
                    detail: true,
                },
            },
            {
                field: "client_user_id",
                hidden: {
                    detail: true,
                    update: true,
                    create: true,
                },
            },

            {
                field: "diagnose_main",
                sort: "name",
                enabled: true,
            },

            {
                field: "diagnose_secondary_multi",
                relatedTable: "disorders",
                displayTemplate: "{{name}}",
                enabled: true,
                sort: "name",
            },
            {
                field: "lead",
                createFilter: (inputValue: string) => leadsSearchSettings(inputValue),
            },
            {
                field: "country",
                sort: "name",
                enabled: true,
            },
        ],
    },
    dataGridOptions: {
        fieldsWithExtraOptions: [
            {
                field: "user_first_name",
                sortable: false,
            },
            {
                field: "user_last_name",
                sortable: false,
            },
        ],
    },
} as UseRecordsOptions;
