import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, UseFormReturn } from "react-hook-form";
import { Link } from "react-router-dom";
import { ClinicsDropdown } from "../../../components/general-dropdowns/clinics-dropdown/ClinicsDropdown";
import { UsersDropdown } from "../../../components/general-dropdowns/users-dropdown/UsersDropdown";
import { RoomsDropdown } from "../../../components/general-dropdowns/rooms-dropdown/RoomsDrodpdown";

type SchedulesHeaderProps = {
  entityForm: UseFormReturn<any>;
};

export const SchedulesHeader = ({ entityForm }: SchedulesHeaderProps) => {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: "64px",
        backgroundColor: "background.paper",
        zIndex: 100,
        p: 3,
        mr: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" color="primary" mb={2}>
          Criar horários
        </Typography>
        <Button variant="outlined" color="primary" size="small" component={Link} to="/schedules">
          Ver Horários
        </Button>
      </Box>

      <Grid container spacing={2} mb={2} alignItems="center">
        <Grid item xs={12} md={6} display="flex" gap={2}>
          <ClinicsDropdown entityForm={entityForm} />
          <UsersDropdown entityForm={entityForm} />
          <RoomsDropdown entityForm={entityForm} />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="startDate"
            control={entityForm.control}
            render={({ field }) => (
              <DatePicker label="Start Date" onChange={(date: any) => field.onChange(date)} value={field.value} />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="endDate"
            control={entityForm.control}
            render={({ field }) => (
              <DatePicker label="End Date" onChange={(date: any) => field.onChange(date)} value={field.value} />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2} textAlign="right">
          <Button type="submit" variant="contained" color="secondary">
            Create Schedules
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
