import { useUser } from "../../../api/useAuth";
import { useRecords } from "../../../api/useRecords";
import { ROLES } from "../../../utils/constants";
import { useFieldSelectOptions } from "../../../utils/useFieldSelectOptions";
import { WorkLog } from "../types";
import { calculateTimeOffTotals } from "./calculations";
import { createTimeOffEntries } from "./general";

export const useWorkLogs = (startDate: Date, endDate: Date, userId?: string) => {
  const dateFilter = {
    start_date: { _gte: startDate.toISOString(), _lte: endDate.toISOString() },
  };

  const technicianUser = userId ? { technician: { user_id: { id: { _eq: userId } } } } : {};

  const user = userId ? { user: { id: { _eq: userId } } } : {};

  const { data: currentUser } = useUser();
  const isCurrentUserAdmin =
    currentUser?.role.id === ROLES.admin ||
    currentUser?.role.id === ROLES.shilan ||
    currentUser?.role.id === ROLES.accounting ||
    currentUser?.role.id === ROLES.virtual_assistant;

  const { data: sessions, status: sessionsStatus } = useRecords(
    "sessions",
    "id,start_date,end_date,title,technician.user_id.*",
    {
      extraOptions: {
        filter: {
          ...dateFilter,
          ...technicianUser,
          status: { _eq: "done" },
          service: { _nnull: true },
        },
        pageSize: -1,
      },
    }
  );

  const { data: schedules, status: schedulesStatus } = useRecords("schedules", "id,start_date,end_date,notes,user.*", {
    extraOptions: {
      filter: {
        ...dateFilter,
        ...user,
      },
      pageSize: -1,
    },
  });

  const { data: timeOffRaw, status: timeOffStatus } = useRecords("time_off", "*.*", {
    extraOptions: {
      filter: {
        ...dateFilter,
        ...user,
      },
      pageSize: -1,
    },
  });
  const year = startDate.getFullYear();

  const { data: fullYearTimeOff, status: fullYearTimeOffStatus } = useRecords("time_off", "*.*", {
    extraOptions: {
      filter: {
        start_date: { _gte: `${year}-01-01` },
        end_date: { _lt: `${year + 1}-01-01` },
        user: { id: { _eq: userId } },
      },
    },
  });

  const timeOffOptions = useFieldSelectOptions("time_off", "type");

  const workLogs =
    sessionsStatus === "success" && schedulesStatus === "success"
      ? createWorklogs(sessions.items, schedules.items)
      : [];

  const timeOff = timeOffStatus === "success" ? createTimeOffEntries(timeOffRaw.items) : [];


  const yearHolidays = fullYearTimeOff ? calculateTimeOffTotals(fullYearTimeOff.items) : { totalHolidays: 0 };
  const thisYearHolidays = yearHolidays.totalHolidays;

  const isLoading =
    sessionsStatus === "loading" ||
    schedulesStatus === "loading" ||
    timeOffStatus === "loading" ||
    fullYearTimeOffStatus === "loading";
  const isError =
    sessionsStatus === "error" ||
    schedulesStatus === "error" ||
    timeOffStatus === "error" ||
    fullYearTimeOffStatus === "error";

  return {
    workLogs,
    timeOff,
    timeOffTypes: timeOffOptions,
    thisYearHolidays,
    currentUser,
    isCurrentUserAdmin,
    isLoading,
    isError,
  };
};

export const createWorklogs = (sessions: any[], schedules: any[]): WorkLog[] => {
  const worklogs: WorkLog[] = [];

  sessions.forEach((session) => {
    worklogs.push({
      id: session.id,
      start_date: session.start_date,
      end_date: session.end_date,
      user: session.technician?.user_id,
      type: "clients",
    });
  });

  schedules.forEach((schedule) => {
    worklogs.push({
      id: schedule.id,
      start_date: schedule.start_date,
      end_date: schedule.end_date,
      user: schedule.user,
      type: "schedules",
      notes: schedule.notes,
    });
  });

  return worklogs;
};
