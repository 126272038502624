import { Typography, Box } from "@mui/material";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";

export const TimeOffSummary = () => {
    const {
        totals: { totalHolidays, totalSickLeaveDays },
        thisYearHolidays,
    } = useContext(StaffWorkLogsContext);

    return (
        <Box sx={{ display: "flex", gap: 10 }}>
            <Box>
                <Typography color="primary" variant="h6">
                    Férias
                </Typography>
                {totalHolidays > 0 && <Typography>Mês: {totalHolidays} dias</Typography>}
                {thisYearHolidays > 0 && <Typography>Ano: {thisYearHolidays} dias</Typography>}
            </Box>
            {totalSickLeaveDays > 0 && (
                <Box>
                    <Typography color="primary" variant="h6">
                        Baixa
                    </Typography>
                    <Typography>{totalSickLeaveDays} dias</Typography>
                </Box>
            )}
        </Box>
    );
};
