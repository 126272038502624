import { ROLES } from "./constants";

export const filterUsersRoles = {
    _or: [
        {
            _and: [
                {
                    role: {
                        id: {
                            _in: [
                                ROLES.admin,
                                ROLES.shilan,
                                ROLES.neurofeedback_director,
                                ROLES.reception_director,
                                ROLES.technical_director,
                                ROLES.nfb_techncians,
                                ROLES.nfb_and_theurapist,
                                ROLES.theurapist,
                                ROLES.recepcionist,
                                ROLES.callcenter
                            ],
                        },
                    },
                },
                {
                    status: {
                        _eq: "active",
                    },
                },
            ],
        },
    ],
};
