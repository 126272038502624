import { Box, Tooltip, Typography } from "@mui/material";
import { Avatar } from "../../components/fieldsViews/fields/Avatar";
import { UserFromAPI } from "../../types/auth.types";

type ProcessResponsibleUsersProps = {
    title: string;
    responsibleUsers?: Array<UserFromAPI>;
};

export const ProcessResponsibleUsers = ({ title, responsibleUsers }: ProcessResponsibleUsersProps) => {
    const MAX_USERS_TO_SHOW = 6;

    return (
        <Box sx={{ mb: 2 }}>
            <Box>
                <Typography variant="body1" color="primary">
                    {title}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {responsibleUsers && responsibleUsers.length > 0 ? (
                    <>
                        {responsibleUsers.slice(0, MAX_USERS_TO_SHOW).map((user) => {
                            return (
                                <Box key={user.id} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Avatar styles={{ width: 25, height: 25 }} value={user.avatar as unknown as string} />
                                    <Typography variant="body1">{user.first_name}</Typography>
                                </Box>
                            );
                        })}
                        {responsibleUsers.length > MAX_USERS_TO_SHOW && (
                            <Tooltip title={responsibleUsers.slice(MAX_USERS_TO_SHOW).map(user => user.first_name).join(', ')}>
                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                    +{responsibleUsers.length - MAX_USERS_TO_SHOW} mais
                                </Typography>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <Typography variant="body1">Não há {title.toLowerCase()}</Typography>
                )}
            </Box>
        </Box>
    );
};
