import { Button, Unstable_Grid2 as Grid } from "@mui/material";
import { Service } from "./ordersGridTypes.types";
import { findField } from "../../utils/findField";
import { renderFieldInput } from "../../components/fieldsInputs/renderFieldInput";
import { useForm } from "react-hook-form";
import { useFields } from "../../api/useFields";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";
import { RecordContextType } from "../../components/record/Record";

type OrdersServiceSearchProps = {
    setItem: React.Dispatch<React.SetStateAction<Service | null>>;
    recordContext: RecordContextType;
};

const orderFormOptions = {
    fieldsWithExtraOptions: [
        {
            field: "service_variant",
            relatedWith: "service",
            dependency: {
                field: "service",
                placeHolder: "Selecione um serviço",
            },
            createFilter: (params: any) => filterVariants(params),
            fieldsQuery: "*.*,service.*.*",
            enabled: true,
            sort: "agreement.name",
        },

        {
            field: "service",
            interfereWith: "service_variant",
            createFilter: () => filterServices(),
            interfereWithLogic: (newValue: any, extraOptions: any, entityForm: any) => true,
            enabled: true,
            sort: "name",
        },
    ],
};

const filterVariants = (params: any) => {
    var filter = {};

    if (!params || !params.id) return {};
    filter = {
        _and: [
            {
                service: {
                    _eq: params.id,
                },
            },
            {
                _or: [
                    {
                        is_disabled: {
                            _null: true,
                        },
                    },
                    {
                        is_disabled: {
                            _eq: false,
                        },
                    },
                ],
            },
        ],
    } as any;

    return filter;
};

const filterServices = () => {
    return {
        id: {
            _nin: [40, 38, 37],
        },
    };
};

export const OrdersServiceSearch = ({ setItem, recordContext }: OrdersServiceSearchProps) => {
    const { formatedFields: serviceVariantFields, status: serviceVariantFieldsStatus } = useFields("service_variant");
    const { formatedFields: orderItemFields, status: orderItemFieldsStatus } = useFields("order_items");
    const entityForm = useForm();

    if (serviceVariantFieldsStatus !== "success" && orderItemFieldsStatus !== "success") return null;

    const serviceField = findField(serviceVariantFields, "service");
    serviceField && (serviceField.settings.required = recordContext.item?.order_items?.length > 0 ? false : true);
    const serviceVariantField = findField(orderItemFields, "service_variant");

    const selectItem = () => {
        if (!entityForm.getValues().service_variant) {
            alert("Selecione uma variante");
            return;
        }

        const { item } = entityForm.getValues().service_variant;

        setItem({
            variantId: item.id,
            sku: item.sku,
            serviceName: item.service.name,
            agreementName: item.agreement.name,
            type: item.type,
            price: item.price,
            category: item.service.category
        });
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid xs={4}>
                {serviceField &&
                    renderFieldInput(
                        serviceField,
                        { service: null },
                        "group",
                        entityForm,
                        getFieldExtraOption(orderFormOptions.fieldsWithExtraOptions, serviceField)
                    )}
            </Grid>
            <Grid xs={4}>
                {serviceVariantField &&
                    renderFieldInput(
                        serviceVariantField,
                        { service: null },
                        "group",
                        entityForm,
                        getFieldExtraOption(orderFormOptions.fieldsWithExtraOptions, serviceVariantField)
                    )}
            </Grid>
            <Grid>
                <Button size="large" onClick={selectItem}>
                    Adicionar
                </Button>
            </Grid>
        </Grid>
    );
};
