import { calculateTotalOfOrders } from "../../orders/utils";
import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatCurrency";
import { BankTransaction } from "./OrderAssociateComponent";
import { isWithinTwentyPercentRange } from "./utils";

type TotalAmountOfAssociatedOrdersProps = {
    orders: Array<any>;
    type: "list" | "detail";
    bankTransaction: BankTransaction;
};

export const TotalAmountOfAssociatedOrders = ({ bankTransaction, orders, type }: TotalAmountOfAssociatedOrdersProps) => {
    // Calculate the total amount of associated orders.
    const total = calculateTotalOfOrders(orders);

    const isPOSorOnlinePayment = bankTransaction?.category?.id === 91 || bankTransaction?.category?.id === 117;

    // Determine the color based on whether the amount is within the 20% range
    const color = isWithinTwentyPercentRange(total, bankTransaction?.amount) ? "#2ECDA7" : "error.main";

    // Calculate the commission amount and percentage (only for POS/Online payments).
    const commissionAmount = isPOSorOnlinePayment ? total - parseFloat(bankTransaction?.amount) : 0;
    const commissionPercentage = isPOSorOnlinePayment ? (commissionAmount / total) * 100 : 0;

    return (
        <Box>
            {type === "detail" && <Typography variant="body1">Total de compras associadas</Typography>}
            <Typography variant={type === "detail" ? "h5" : "body1"} color={color}>
                {formatCurrency(total)}
            </Typography>
            {isPOSorOnlinePayment && (
                <Typography variant="caption" color="text.secondary">
                    Comissão: {formatCurrency(commissionAmount)} ({commissionPercentage.toFixed(2)}%)
                </Typography>
            )}
        </Box>
    );
};
