import { Box, Button } from "@mui/material";
import { useCreateRecord } from "../../api/useCreateRecord";
import { ReactNode, useContext } from "react";
import { RecordContext } from "../record/Record";
import { RelatedRecordsContext } from "../recordsRelated/RecordsRelated";
import { AsyncAutoCompleteNew, AsyncAutoCompleteNewProps } from "../async-auto-complete/AsyncAutoCompleteNew";
import { useForm, UseFormReturn } from "react-hook-form";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { AssociateItemFunction } from "../../types/associate-record.types";

type AssociateRecordProps = {
    customComponent?: ReactNode;
    associateItemToRecord: AssociateItemFunction;
    asyncAutoCompleteProps: Omit<AsyncAutoCompleteNewProps, "entityForm">;
};

export const AssociateRecord = ({ customComponent, associateItemToRecord, asyncAutoCompleteProps }: AssociateRecordProps) => {
    const recordContext = useContext(RecordContext);
    const relatedRecordsContext = useContext(RelatedRecordsContext);

    const entityForm = useForm();
    const updateRecord = useUpdateRecord({ queryInvalidatePrefix: `${relatedRecordsContext?.entity}` });
    const createRecord = useCreateRecord({ queryInvalidatePrefix: `${relatedRecordsContext?.entity}` });

    const handleFormSubmit = (data: any) => {
        associateItemToRecord(data, recordContext, relatedRecordsContext, { updateRecord, createRecord });
    };

    return (
        <Box component="form" sx={{ display: "flex", gap: 6, minWidth: 700 }} onSubmit={entityForm.handleSubmit(handleFormSubmit)}>
            {customComponent || <AsyncAutoCompleteNew {...asyncAutoCompleteProps} entityForm={entityForm as unknown as UseFormReturn} enabled={false}/>}
            <Button type="submit">Associar</Button>
        </Box>
    );
};
