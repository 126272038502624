import { TableRow, TableCell, IconButton, Button, Box } from "@mui/material";
import { BeachAccess, SickOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { format } from "date-fns";
import { WorkLog, TimeOff } from "../types";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { useContext } from "react";
import { DayEntries } from "./DayEntries";
import { pt } from "date-fns/locale";
import { calculateDayStats } from "../utils/calculations";

type DaySummaryProps = {
    date: Date;
    expandedRows: { [key: string]: boolean };
    toggleRowExpansion: (date: string) => void;
    dateString: string;
    organizedData: { [key: string]: { workLogs: WorkLog[]; timeOffs: TimeOff[] } };
};

export const DaySummary = ({ date, expandedRows, toggleRowExpansion, dateString, organizedData }: DaySummaryProps) => {
    const workLogs = organizedData[dateString].workLogs;
    const timeOffs = organizedData[dateString].timeOffs;

    const { workLogForm, payment, setIsModalOpen, userId } = useContext(StaffWorkLogsContext);
    const { timeStart, timeEnd, workHours, clientsHours } = calculateDayStats(workLogs);

    const isExpanded = expandedRows[dateString] || false;

    const handleAddClick = () => {
        const startDate = new Date(date.setHours(10, 0, 0));
        const endDate = new Date(date.setHours(20, 0, 0));

        workLogForm.setValue("start_date", startDate);
        workLogForm.setValue("end_date", endDate);
        workLogForm.setValue("notes", "");
        workLogForm.setValue("type", "schedules");
        workLogForm.setValue("clinic_id", 1 );
        workLogForm.setValue("user", userId);
        setIsModalOpen(true);
    };

    const getTimeOffIcons = (timeOffs: Array<TimeOff>) => {
        const icons = [];

        for (const timeOff of timeOffs) {
            if (timeOff.type === "holiday") {
                icons.push(<BeachAccess key={`holiday-${timeOff.id}`} color="secondary" />);
            } else if (timeOff.type === "sick_leave") {
                icons.push(<SickOutlined key={`sick-${timeOff.id}`} color="error" />);
            }
        }

        return icons;
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <Box display="flex" alignItems="center" gap={4}>
                        <IconButton size="small" onClick={() => toggleRowExpansion(dateString)}>
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        {format(date, "EEEE, d MMM", { locale: pt })}
                        {timeOffs.length > 0 && getTimeOffIcons(timeOffs)}
                    </Box>
                </TableCell>
                <TableCell align="center">{timeStart}</TableCell>
                <TableCell align="center">{timeEnd}</TableCell>
                <TableCell align="center">{workHours.toFixed(1)}</TableCell>
                <TableCell align="center">{clientsHours.toFixed(1)}</TableCell>

                <TableCell align="center">
                    <Button disabled={payment?.admin_validated} variant="outlined" size="small" onClick={handleAddClick}>
                        Adicionar
                    </Button>
                </TableCell>
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell colSpan={6} sx={{ padding: 0 }}>
                        <DayEntries
                            date={date}
                            workLogs={organizedData[dateString].workLogs}
                            timeOffs={organizedData[dateString].timeOffs}
                        />
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
