import { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AvailabilityFormFinder } from "./components/AvailabilityFormFinder";
import { AvailabilityResults } from "./components/AvailabilityResults";
import { AvailabilityPreferences, AvailabilitySlot, WeeklyAvailabilityFromApi } from "./utils/types";
import { useForm } from "react-hook-form";

export const AvailabilityFinder = () => {
    const entityForm = useForm<AvailabilityPreferences>({
        defaultValues: {
            clinic_id: 1,
            startDate: new Date(),
            dayPeriod: "both",
            preferredDays: [],
            treatment: undefined,
            lead: undefined,
            firstService: undefined,
            requireConsecutiveSessions: false,
            secondService: undefined,
            technician: undefined,
        },
    });
    const [preferences, setPreferences] = useState<AvailabilityPreferences | null>(null);
    const [selectedSlots, setSelectedSlots] = useState<AvailabilitySlot[]>([]);
    const [weeks, setWeeks] = useState<WeeklyAvailabilityFromApi[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ p: 3 }}>
                <Paper sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h5" color="primary" mb={6} ml={1}>
                        Encontrar Disponibilidades para Sessões
                    </Typography>

                    <AvailabilityFormFinder
                        setIsLoading={setIsLoading}
                        entityForm={entityForm}
                        selectedSlots={selectedSlots}
                        setSelectedSlots={setSelectedSlots}
                        setWeeks={setWeeks}
                        isLoading={isLoading}
                        setPreferences={setPreferences}
                    />
                </Paper>

                {preferences && weeks.length > 0 && (
                    <AvailabilityResults
                        preferences={preferences}
                        selectedSlots={selectedSlots}
                        setSelectedSlots={setSelectedSlots}
                        weeks={weeks}
                        setWeeks={setWeeks}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                )}
            </Box>
        </LocalizationProvider>
    );
};
