import { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, CircularProgress, Typography, Box } from "@mui/material";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { useRecords } from "../../../api/useRecords";
import { Avatar } from "../../../components/fieldsViews/fields/Avatar";

type SessionStatusFieldProps = {
    value: string;
    eventId: string | number;
    setPreviewIsOpen: (value: boolean) => void;
    refetchSessions: () => void;
};

export const SessionTechnicianField = ({ value, eventId, setPreviewIsOpen, refetchSessions }: SessionStatusFieldProps) => {
    const [inputValue, setInputValue] = useState<string | null>(value);
    const updateStatus = useUpdateRecord();
    const { data, status } = useRecords("technicians", "*.*", {
        extraOptions: { filter: { _or: [{ disabled: { _neq: true } }, { disabled: { _null: true } }] }, sort: "user_id.first_name" },
    });

    const handleChange = async (event: SelectChangeEvent) => {
        const response = await updateStatus.mutateAsync({
            entity: "sessions",
            id: eventId,
            data: {
                technician: event.target.value,
            },
        });

        if (response.id) {
            setPreviewIsOpen(false);
            refetchSessions();
        }

        setInputValue(event.target.value as string);
    };

    if (status === "loading") return <CircularProgress />;
    if (status === "error") return <Typography>Erro ao carregar técnicos</Typography>;
    return (
        <FormControl fullWidth>
            <InputLabel>Técnico</InputLabel>
            <Select value={inputValue || ""} onChange={handleChange} label="Técnico" sx={{ height: 56 }}>
                {data?.items?.map((item: any) => {
                    const user = item.user_id;

                    return (
                        <MenuItem key={item.id} value={item.id}>
                            <Box display="flex" alignItems="center" gap={4}>
                                <Avatar value={user.avatar} />
                                <Typography>
                                    {user.first_name} {user.last_name}
                                </Typography>
                            </Box>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
