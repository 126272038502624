import { useQuery } from "@tanstack/react-query";
import { api } from "./config";

export const useImage = (id?: string, isOriginal?: boolean) => {
    const queryKey = `image-${id}`;
    return useQuery({
        queryKey: [queryKey],
        queryFn: () => getImage(id, isOriginal),
        refetchOnWindowFocus: true,
        enabled: true,
    });
};

export const getImage = async (id?: string, isOriginal?: boolean) => {
    if (!id) return "";

    const transformation = isOriginal ? "fit" : "key=thumbnail";
    const response = await api({
        method: "GET",
        url: `/assets/${id}?${transformation}`,
        responseType: "blob",
    });

    const blob = response.data;
    const objectUrl = URL.createObjectURL(blob);

    return objectUrl;
};
