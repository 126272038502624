import { getChipLabel } from "../../../components/chips/CustomChip";
import { queryClient } from "../../../main";
import { findField } from "../../../utils/findField";

export const renderPartnerSelectedOption = (option: any) => {
    const fields = queryClient.getQueryData(["professional_partners-fields"]) as any;
    const specializationField = findField(fields, "specialization");

    specializationField.settings = specializationField.meta;
    const specializationFieldLabel = getChipLabel(specializationField, option.specialization);

    return `${option.name} (${specializationFieldLabel})`;
}


