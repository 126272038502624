import { Box, Typography } from "@mui/material";
import { CustomChip } from "../../../components/chips/CustomChip";
import { useFields } from "../../../api/useFields";

export type RenderPartnerAssociateOptionProps = {
    option: any;
    props: any;
};

export const RenderPartnerAssociateRecord = ({ option, props }: RenderPartnerAssociateOptionProps) => {
    const { formatedFields } = useFields("professional_partners");

    const specializationField = formatedFields.find((field) => field.field === "specialization");

    return (
        <Box {...props} sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Typography variant="body1">{option.name}</Typography>
            {specializationField && <CustomChip field={specializationField} label={option.specialization} />}
        </Box>
    );
};
