import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { format } from "date-fns";
import { renderFieldView } from "../../components/fieldsViews/renderFieldView";
import { findField } from "../../utils/findField";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";

export const OrderHeader = ({ actions }: { actions?: any }) => {
    const recordContext = useContext(RecordContext);

    if (!recordContext) return null;

    const paymentField = findField(recordContext.formatedFields, "payment_method");
    const treatmentNameField = findField(recordContext.formatedFields, "treatment");
    const bankTransactionField = findField(recordContext.formatedFields, "bank_transaction");
    const bankTransactionExtraOptions = recordContext.options?.fieldsWithExtraOptions;
    const bankTransactionFieldExtraOptions = bankTransactionExtraOptions
        ? getFieldExtraOption(bankTransactionExtraOptions, bankTransactionField)
        : null;

    const invoiceNumberField = findField(recordContext.formatedFields, "invoice_number");


    return (
        <DetailHeader collection={recordContext.collection} actions={actions}>
            <Grid container spacing={10} alignItems="flex-start" xs={12}>
                <Grid xs={12} lg={6}>
                    <Typography variant="body1" color="primary">
                        Tratamento:
                    </Typography>
                    {treatmentNameField &&
                        renderFieldView(treatmentNameField, { treatment: recordContext.item.treatment }, "group")}
                </Grid>
                <Grid xs={12} lg={3}>
                    <Typography variant="body1" color="primary" mb={2}>
                        Data:
                    </Typography>
                    <Typography variant="body1">{format(new Date(recordContext.item.order_date), "yyyy-MM-dd")}</Typography>
                </Grid>
                <Grid xs={12} lg={3}>
                    <Typography variant="body1" color="primary" mb={1}>
                        Meio de Pagamento:
                    </Typography>
                    {paymentField &&
                        renderFieldView(
                            paymentField,
                            { payment_method: recordContext.item.payment_method },
                            "group",
                            bankTransactionFieldExtraOptions
                        )}
                </Grid>
                <Grid xs={12} lg={3}>
                    <Typography variant="body1" color="primary" mb={1}>
                        Número da fatura:
                    </Typography>
                    {invoiceNumberField &&
                        renderFieldView(invoiceNumberField, { invoice_number: recordContext.item.invoice_number }, "group")}
                </Grid>
                <Grid xs={12} lg={3}>
                    {bankTransactionField && recordContext.item[bankTransactionField.field] && (
                        <Box>
                            <Typography variant="body1" color="primary" mb={1}>
                                Movimento bancário:
                            </Typography>
                            {renderFieldView(
                                bankTransactionField,
                                { bank_transaction: recordContext.item.bank_transaction },
                                "group",
                                bankTransactionFieldExtraOptions
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </DetailHeader>
    );
};
