import { InstantSearch, SearchBox, InfiniteHits, Highlight } from "react-instantsearch";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import "instantsearch.css/themes/satellite.css";
import { Box, Dialog, DialogContent, Divider, ListItemButton, Typography } from "@mui/material";
import { useImage } from "../../api/useImage";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";

const { searchClient } = instantMeiliSearch(
    "https://search.neuroimprove.pt",
    "f6ae99d0316f5b59dc455306ae0681f7edd4a8e7b4418034b86aa5970d63d24f",
    { placeholderSearch: false }
);

const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    const truncated = text.substring(0, maxLength) + "...";
    return truncated.replace(/<mark>/g, '<mark class="ais-Highlight-highlighted">');
};

export const ProcessesSearchDialog = () => {
    const { processesSearch } = useStore();

    const handleClose = () => {
        processesSearch.setIsOpen(false);
    };
    return (
        <Dialog open={processesSearch.isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <DialogContent>
                <InstantSearch indexName="processes" searchClient={searchClient}>
                    <Typography variant="h5" sx={{ mb: 4 }}>
                        Pesquisa na biblioteca de processos e conhecimento.
                    </Typography>
                    <SearchBox autoFocus={true} />
                    <InfiniteHits
                        hitComponent={Hit}
                        translations={{ showMoreButtonText: "Ver mais", showPreviousButtonText: "Ver menos" }}
                    />
                </InstantSearch>
            </DialogContent>
        </Dialog>
    );
};

const Hit = ({ hit }: { hit: any }) => {
    const navigate = useNavigate();

    const { data: image } = useImage(hit.parentCategory?.picture);
    const { processesSearch } = useStore();

    return (
        <ListItemButton
            key={hit.id}
            onClick={() => {
                navigate(`/processes/parentcategory/${hit.parentCategory.id}/category/${hit.category.id}/${hit.id}`);
                processesSearch.setIsOpen(false);
            }}
            sx={{
                borderLeft: (theme) => `4px solid ${theme.palette.primary.main}`,
                "&:hover": {
                    borderLeft: (theme) => `8px solid ${theme.palette.primary.main}`,
                },
                transition: "border-left-width 0.2s ease",
                mb: 2,
                backgroundColor: "background.paper",
                borderRadius: 1,
                boxShadow: 1,
            }}
        >
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 4, justifyContent: "space-between" }}>
                    <Box>
                        <Typography variant="h6">
                            <Highlight attribute="title" hit={hit} />
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                            <Typography variant="body1">{hit.parentCategory?.name}: </Typography>
                            <Typography variant="body1">{hit.category?.name}</Typography>
                        </Box>
                    </Box>

                    {image && <img src={image} alt={hit.title} style={{ maxWidth: 75, maxHeight: 75 }} />}
                </Box>
                <Divider sx={{ my: 4 }} />
                <Box>
                    <div dangerouslySetInnerHTML={{ __html: truncateText(hit._highlightResult?.content?.value, 300) }} />
                </Box>
            </Box>
        </ListItemButton>
    );
};
