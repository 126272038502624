import {
  GridColDef,
  GridActionsCellItem,
  GridValidRowModel,
  GridRowsProp,
  GridRowId,
  GridTreeNodeWithRender,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { calculateRowTotal } from "./utils";
import { Box, TextField, Typography, Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useContext, useState } from "react";
import { RecordContext } from "../../components/record/Record";
import { Controller } from "react-hook-form";

type ColumnsOptions = {
  rows: GridRowsProp;
  setRows?: React.Dispatch<React.SetStateAction<readonly GridValidRowModel[]>>;
  setRowsToDelete?: (any: any) => void;
  type: "create" | "detail" | "update";
};

export const useColumns = (options: ColumnsOptions) => {
  const columns: GridColDef[] = [
    { field: "sku", headerName: "Sku", width: 150 },
    {
      field: "serviceName",
      headerName: "Serviço",
      width: 300,
      renderCell: (params) => {
        return <ServiceNameCell row={params.row} options={options} />;
      },
    },
    { field: "agreementName", headerName: "Acordo", width: 150 },
    { field: "type", headerName: "Tipo", width: 150 },
    { field: "quantity", headerName: "Quantidade", width: 100, editable: isEditable(options.type), type: "number" },
    {
      field: "price",
      headerName: "Preço Unit",
      width: 100,
      type: "number",
      editable: isEditable(options.type),
      renderCell: (params) => `€ ${params.row.price}`,
    },
    {
      field: "discount",
      headerName: "Desconto",
      width: 100,
      editable: isEditable(options.type),
      renderCell: (params) => `${params.row.discount || 0} %`,
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
      type: "number",
      renderCell: (params) => `€ ${calculateRowTotal(params.row)}`,
    },
  ];

  const actions = createActions(options);

  if (actions) {
    columns.push(actions);
  }

  return columns;
};

const handleDeleteClick =
  (
    id: GridRowId,
    rows: GridRowsProp,
    setRows: React.Dispatch<React.SetStateAction<readonly GridValidRowModel[]>>,
    setRowsToDelete?: (any: any) => void
  ) =>
  () => {
    setRowsToDelete && setRowsToDelete(rows.filter((row) => row.id === id));
    setRows(rows.filter((row) => row.id !== id));
  };

const createActions = (options: ColumnsOptions) => {
  if (options.type === "detail") return;

  return {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }: any) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={options.setRows && handleDeleteClick(id, options.rows, options.setRows, options.setRowsToDelete)}
          color="inherit"
        />,
      ];
    },
  };
};

const isEditable = (type: string) => {
  switch (type) {
    case "create":
    case "update":
      return true;
    case "detail":
      return false;
    default:
      return false;
  }
};

type ServiceNameCellProps = {
  row: any;
  options: ColumnsOptions;
};

const ServiceNameCell = ({ row, options }: ServiceNameCellProps) => {
  const recordContext = useContext(RecordContext);
  const orderForm = recordContext?.entityForm;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSave = () => {
    row.alternative_description = orderForm?.getValues("alternative_description");
    const updatedRow = { ...row };
    options.setRows && options.setRows(options.rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
    handleCloseDialog();
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body1">{row.serviceName}</Typography>

        {options.type !== "detail" && <Edit color="secondary" fontSize="small" onClick={handleOpenDialog} />}
      </Box>
      <Box>
        <Typography variant="caption">
          {orderForm?.getValues("alternative_description") || row.alternative_description}
        </Typography>
      </Box>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogContent sx={{ width: 500 }}>
          <Controller
            name="alternative_description"
            control={orderForm?.control}
            defaultValue={row.alternative_description || ""}
            render={({ field }) => (
              <TextField
                autoFocus
                margin="dense"
                id="alternative_description"
                label={`Descrição Alternativa para ${row.serviceName}`}
                type="text"
                fullWidth
                variant="standard"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
