import { Box, Autocomplete, TextField, MenuItem, Button } from "@mui/material";
import { Avatar } from "../../../../components/fieldsViews/fields/Avatar";
import { useState } from "react";

type TechnicianSelectorProps = {
    users: any;
    onSelect: (ids: Array<string>) => void;
    mode?: "copy" | "update";
};

export const TechnicianSelector = ({ users, onSelect, mode = "copy" }: TechnicianSelectorProps) => {
    const [selectedTechs, setSelectedTechs] = useState<Array<any>>([]);

    const handleCopyClick = () => {
        const items = selectedTechs.map((tech) => tech.id);
        onSelect(items);
    };

    return (
        <Box p={1} onClick={(e) => e.stopPropagation()}>
            <Autocomplete
                multiple
                sx={{
                    "& .MuiInputBase-root": {
                        padding: "2px 4px",
                    },
                    "& .MuiAutocomplete-popper": {
                        "& .MuiAutocomplete-listbox": {
                            maxHeight: "400px",
                        },
                    },
                }}
                options={users?.items || []}
                value={selectedTechs}
                getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        variant="outlined" 
                        placeholder="Search technicians..." 
                        fullWidth
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }}
                    />
                )}
                onChange={(_, value) => {
                    setSelectedTechs(value);
                }}
                renderOption={(props, option) => (
                    <MenuItem {...props}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Avatar value={option.avatar?.id} />
                            {option.first_name} {option.last_name}
                        </Box>
                    </MenuItem>
                )}
                slotProps={{
                    popper: {
                        placement: "top-start"
                    }
                }}
                disablePortal
            />
            <Box sx={{ mt: 1, display: "flex", alignItems: "center", gap: 2 }}>
                <Button variant="contained" size="small" onClick={handleCopyClick} sx={{ mt: 1 }} fullWidth>
                    {mode === "copy" ? "Copy" : "Update"}
                </Button>
            </Box>
        </Box>
    );
};
