import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Holiday = {
  date: string;
  name: string;
  type: "national" | "local";
  location?: string;
};

export const usePortugalHolidays = (date: Date) => {
  return useQuery({
    queryKey: ["portugal-holidays"],
    queryFn: () => getPortugalHolidays(date),
  });
};

export const getPortugalHolidays = async (date: Date): Promise<Holiday[]> => {
  try {
    // Fetch national holidays from Nager.Date
    const year = date.getFullYear();
    const response = await axios.get(
      `https://calendarific.com/api/v2/holidays?api_key=da34YuKJJM2OUTw8CRcptz15NE1VVKO0&country=pt&year=${year}&type=national`
    );
    const nationalHolidays = response.data.response.holidays;

    // Transform to your format
    const holidays: Holiday[] = nationalHolidays.map((holiday: any) => ({
      date: holiday.date.iso,
      name: holiday.name,
      type: "national",
    }));

    // Add local holidays manually
    // You could maintain these in a separate configuration file
    const localHolidays: Holiday[] = [
      {
        date: `${year}-06-24`,
        name: "São João",
        type: "local",
        location: "Porto",
      },
      // Add other local holidays as needed
    ];

    return [...holidays, ...localHolidays];
  } catch (error) {
    console.error("Failed to fetch holidays:", error);
    return [];
  }
};
