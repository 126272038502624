import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { Link } from "react-router-dom";
import store2 from "store2";
import { implementFieldConditions } from "../../../utils/implementFieldConditions";
import { handleLabel } from "../../../utils/handleLabels";
import { FieldProps } from "../renderFieldView";
import { isArray } from "lodash";
import { FieldExtraOption, FormatedField } from "../../../types/fields.types";
import { handleTypesOfRelatedFields } from "../../../utils/handleTypesOfRelatedFields";
import { baseUrl } from "../../../api/config";

type FieldValue = {
    [key: string]: any;
};

interface RelatedFieldProps extends FieldProps {}

export function RelatedField({ field, value: apiValue, fieldView, extraOptions }: RelatedFieldProps) {
    if (!apiValue) return null;
    const values = isArray(apiValue) ? [...apiValue] : [apiValue];

    const fieldValues = handleTypesOfRelatedFields(field, values);

    return fieldValues.map((value: any) => {
        const fieldTemplate = field.settings.display_options?.template;
        const manyToAnyFieldTemplate = value.template;

        return (
            <Box key={value.id} sx={{ display: "flex", alignItems: "center", justifyContent: "start", gap: 2, mb: 1 }}>
                {isUser(value, field, extraOptions)}
                {value && value.id && fieldView !== "grid" && implementFieldConditions(field, "withLink") ? (
                    <Button
                        component={Link}
                        to={generateUrl(field, value, extraOptions)}
                        variant="text"
                        size="large"
                        color="secondary"
                        sx={{
                            padding: 1,
                            textTransform: "none",
                        }}
                    >
                        {handleLabel(value, fieldTemplate || manyToAnyFieldTemplate, extraOptions)}
                    </Button>
                ) : (
                    <Typography variant="body1">
                        {handleLabel(value, fieldTemplate || manyToAnyFieldTemplate, extraOptions)}
                    </Typography>
                )}
                {value && value.id && fieldView !== "grid" && implementFieldConditions(field, "withLink") ? (
                    <IconButton sx={{ p: 0 }} color="secondary" component={Link} to={generateUrl(field, value)} target="_blank">
                        <OpenInNew />
                    </IconButton>
                ) : null}
            </Box>
        );
    });
}

function isUser(value: FieldValue, field: FormatedField, extraOptions?: FieldExtraOption | FieldExtraOption[]) {
    if (field.settings.relatedTable === "clients") return null;

    const user = handleUserValue(value, field, extraOptions);

    if (user) {
        return (
            <Avatar
                alt={`${user.first_name} ${user.last_name}`}
                sx={{ ml: 1 }}
                src={`${baseUrl}/assets/${user.avatar}?key=thumbnail&access_token=${store2.get("auth").access_token}`}
            />
        );
    }
}

const generateUrl = (field: FormatedField, value: any, extraOptions?: FieldExtraOption) => {
    if (field.settings.type === "manytoany") {
        return `/${value.collection}/${value.item?.id}/detail`;
    }

    if (field.settings?.special?.[0] === "m2m") {
        return `/${extraOptions?.relatedTable}/${value[`${extraOptions?.relatedTable}_id`]?.id}/detail`;
    }

    return `/${field.settings?.relatedTable || extraOptions?.relatedTable}/${value.id}/detail`;
};

const handleUserValue = (value: FieldValue, field: FormatedField, extraOptions?: FieldExtraOption | FieldExtraOption[]) => {
    let user = value?.user_id || value?.directus_users_id;

    const singleExtraOptionRelatedTable = !Array.isArray(extraOptions) && extraOptions?.relatedTable;
    if (singleExtraOptionRelatedTable) {
        user = value?.[`${extraOptions?.relatedTable}_id`]?.user_id;
    }

    const multipleExtraOptionsRelatedTable =
        Array.isArray(extraOptions) && extraOptions?.find((option) => option.field === field.field);

    if (multipleExtraOptionsRelatedTable) {
        user = value?.[`${multipleExtraOptionsRelatedTable.relatedTable}_id`]?.user_id;
    }

    return user;
};
