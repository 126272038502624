import { Box } from "@mui/material";
import { AssociateRecord } from "../../../components/associate-record/AssociateRecord";
import { TotalAmountOfAssociatedOrders } from "./TotalAmountOfAssociatedOrders";
import { renderOrderSelectedOption } from "./renderOrderSelectedOption";
import { associateOrderToTransaction } from "./associateOrderToTransaction";
import { RenderOrderAssociateOption } from "./RenderOrderAssociateOption";
import { useContext } from "react";
import { RelatedRecordsContext } from "../../../components/recordsRelated/RecordsRelated";
import { RecordContext } from "../../../components/record/Record";
import { ORDERS_SINCE_DATE } from "../../../utils/constants";

export type BankTransaction = {
    amount: string;
    category: {
        id: number;
    };
};

export const OrderAssociateComponent = () => {
    const recordContext = useContext(RecordContext);
    const relatedRecordsContext = useContext(RelatedRecordsContext);
    const orders = relatedRecordsContext?.items;


    return (
        <Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
            {orders && recordContext?.item && (
                <TotalAmountOfAssociatedOrders
                    bankTransaction={recordContext?.item as BankTransaction}
                    orders={orders}
                    type="detail"
                />
            )}
            <AssociateRecord
                associateItemToRecord={associateOrderToTransaction}
                asyncAutoCompleteProps={{
                    fieldName: "order",
                    placeholder: "Pesquise a compra",
                    recordsOptions: {
                        entity: "orders",
                        fieldsToQuery: "id,order_date,order_items.*,payment_method,treatment.client.*,treatment.client.user_id.*",
                        labelKey: renderOrderSelectedOption,
                        customFilter: (value: string) => ({
                            _and: [
                                {
                                    bank_transaction: {
                                        _null: true,
                                    },
                                },
                                {
                                    order_date: {
                                        _gte: ORDERS_SINCE_DATE,
                                    },
                                },
                                {
                                    _or: [
                                        {
                                            payment_method: {
                                                _icontains: value.length >= 3 ? convertPaymentMethodToApi(value) : value,
                                            },
                                        },
                                        {
                                            treatment: {
                                                client: {
                                                    client_id: {
                                                        _icontains: value,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        }),
                        renderOption: (props: any, option: any) => (
                            <RenderOrderAssociateOption key={option.id} option={option} props={props} />
                        ),
                    },
                }}
            />
        </Box>
    );
};

const convertPaymentMethodToApi = (value: string): string => {
    const normalizedValue = value.toLowerCase();
    return normalizedValue.length >= 3 && normalizedValue.includes("din") ? "cash" : value;
};
