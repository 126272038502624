import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OrderHeader, OrderItem, OrderItems } from "../screens/orders/ordersGridTypes.types";
import { api } from "./config";
import { createOrderItem } from "./useCreateOrder";

export const useUpdateOrder = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (formData: any) => updateOrder(formData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [`orders-${data.id}`] });
            queryClient.invalidateQueries({ queryKey: [`order_items-items-parentId-${data.id}`] });
        },
    });
};

const updateOrder = async (formData: any) => {
    const orderHeaderData = {
        order_id: formData.order_id,
        treatment: formData.treatment?.id,
        payment_method: formData.payment_method,
        payment_status: formData.payment_status,
        order_date: formData.order_date,
        notes: formData.notes || null,
        bank_transaction: formData.bank_transaction?.id || null,
    } as OrderHeader;

    const orderItemsData = {
        order_id: formData.order_id,
        orderItems: formData.order_items as OrderItem[],
        order_items_to_delete: formData.order_items_to_delete,
    } as OrderItems;

    const response = await updateOrderHeader(orderHeaderData);
    await updateOrderItems(orderItemsData);

    return response.data.data;
};

const updateOrderItems = async (order: OrderItems) => {
    if (!order.orderItems) return;

    await Promise.all(
        order.orderItems.map(async (item) => {
            if (item.newRow && order.order_id) {
                await createOrderItem(order.order_id, item);
            } else {
                await api({
                    method: "PATCH",
                    url: `items/order_items/${item.order_item_id}`,
                    data: item,
                });
            }
        })
    );

    if (order.order_items_to_delete && order.order_items_to_delete.length > 0) {
        await deleteOrderItems(order.order_items_to_delete);
    }
};

const updateOrderHeader = async (order: OrderHeader) => {
    const orderId = order.order_id;

    const updatedOrder = {
        ...order,
        order_id: undefined,
        order_items_to_delete: undefined,
    };

    return await api({
        method: "PATCH",
        url: `items/orders/${orderId}`,
        data: updatedOrder,
    });
};

const deleteOrderItems = async (orderItemsToDelete: OrderItem[]) => {
    if (!orderItemsToDelete || orderItemsToDelete.length === 0) return;

    const orderItemsIds = mapOrderItemsIds(orderItemsToDelete);

    if (orderItemsIds.length === 0) return;

    await api({
        method: "DELETE",
        url: `items/order_items`,
        data: orderItemsIds,
    });
    return orderItemsIds;
};

const mapOrderItemsIds = (orderItemsToDelete: OrderItem[]) => {
    return orderItemsToDelete.map((item) => item.order_item_id).filter((orderItemId) => orderItemId !== undefined);
};
