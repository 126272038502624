import {
    CalendarMonth,
    EuroSymbol,
    Groups,
    TextSnippet,
    EmojiPeople,
    Psychology,
    AddTask,
    AssignmentInd,
    InsertChart,
    Quiz,
    AccountBalance,
    Handshake,
    Call,
    Troubleshoot,
    ListAlt,
} from "@mui/icons-material";
import { MenuItem } from "./MenuItems";

export const mainMenuItems: MenuItem[] = [
    {
        id: "sessions",
        name: "Calendário",
        icon: <CalendarMonth />,
        link: "/sessions",
        collection: "sessions",
    },
    {
        id: "leads",
        name: "Leads",
        icon: <EmojiPeople />,
        link: "/leads",
        collection: "leads",
    },
    {
        id: "clients",
        name: "Clientes",
        icon: <Groups />,
        link: "/clients",
        collection: "clients",
    },
    {
        id: "treatments",
        name: "Tratamentos",
        icon: <Psychology />,
        link: "/treatments",
        collection: "treatments",
    },
    {
        id: "evaluations",
        name: "Avaliações",
        icon: <Troubleshoot />,
        link: "/evaluations",
        collection: "evaluations",
    },
    {
        id: "orders",
        name: "Compras",
        icon: <EuroSymbol />,
        link: "/orders",
        collection: "orders",
    },
    {
        id: "feedbacks",
        name: "Feedbacks",
        icon: <TextSnippet />,
        link: "/feedbacks",
        collection: "feedbacks",
    },
    {
        id: "tasks",
        name: "Tarefas",
        icon: <AddTask />,
        link: "/tasks",
        collection: "tasks",
    },
    {
        id: "customer_surveys",
        name: "Questionários",
        icon: <Quiz />,
        link: "/customer_surveys",
        collection: "customer_surveys",
    },
    {
        id: "candidates",
        name: "Candidatos(as)",
        icon: <AssignmentInd />,
        link: "/candidates",
        collection: "candidates",
    },
    {
        id: "professional_partners",
        name: "Parceiros",
        icon: <Handshake />,
        link: "/professional_partners",
        collection: "professional_partners",
    },
    {
        id: "dashboards",
        name: "Dashboards",
        icon: <InsertChart />,
        link: "/dashboards",
        collection: "dashboards",
    },
    {
        id: "bank_transactions",
        name: "Finanças",
        icon: <AccountBalance />,
        link: "/bank_transactions",
        collection: "bank_transactions",
    },
    {
        id: "calls",
        name: "Chamadas",
        icon: <Call />,
        link: "/calls",
        collection: "calls",
    },
    {
        id: "processes",
        name: "Biblioteca",
        icon: <ListAlt />,
        link: "/processes",
        collection: "processes",
    },
];
