import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { format } from "date-fns";
import { renderFieldInput } from "../../components/fieldsInputs/renderFieldInput";
import { ordersOptions } from "./ordersOptions";
import { findField } from "../../utils/findField";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";
import { UseFormReturn } from "react-hook-form";

type OrderHeaderFieldsProps = { 
    entityForm: UseFormReturn;
}

export const OrderHeaderFields = ({ entityForm }: OrderHeaderFieldsProps) => {
    const recordContext = useContext(RecordContext);

   if (!recordContext) return null; 

    const paymentMethodField = findField(recordContext.formatedFields, "payment_method");
    const dateField = findField(recordContext.formatedFields, "order_date");
    const treatmentField = findField(recordContext.formatedFields, "treatment");
    const bankTransactionField = findField(recordContext.formatedFields, "bank_transaction");
    const bankTransactionFieldExtraOptions = getFieldExtraOption(
        ordersOptions?.groupsOptions.fieldsWithExtraOptions,
        bankTransactionField
    );
 
    return (
        <Grid container alignItems="center" flexGrow={1} justifyContent="flex-start" spacing={3} mt={5}>
            <Grid xs={12} md={6} lg={4}>
                {treatmentField &&
                    renderFieldInput(
                        treatmentField,
                        { treatment: recordContext.item?.treatment || "" },
                        "group",
                        entityForm,
                        getFieldExtraOption(ordersOptions?.groupsOptions.fieldsWithExtraOptions, treatmentField)
                    )}
            </Grid>
            <Grid xs={12} md={6} lg={2}>
                {dateField &&
                    renderFieldInput(
                        dateField,
                        { order_date: recordContext.item?.order_date || format(new Date(), "yyyy-MM-dd HH:mm") },
                        "group",
                        entityForm
                    )}
            </Grid>
            <Grid xs={12} md={6} lg={2}>
                {paymentMethodField &&
                    renderFieldInput(
                        paymentMethodField,
                        { payment_method: recordContext.item?.payment_method || "" },
                        "group",
                        entityForm
                    )}
            </Grid>
            <Grid xs={12} md={6} lg={2}>
                {bankTransactionField &&
                    renderFieldInput(
                        bankTransactionField,
                        { bank_transaction: recordContext.item?.bank_transaction || "" },
                        "group",
                        entityForm,
                        bankTransactionFieldExtraOptions
                    )}
            </Grid>
        </Grid>
    );
};
