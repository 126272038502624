import { RolesManyToMany } from "../types/auth.types";
import { UserFromAPI } from "../types/auth.types";
import { formatDate } from "../utils/dateUtils";
import { Process } from "./types";

export const processUpdatedAt = (process: Process) => {
    const updatedAt = process.date_updated || process.date_created;
    return formatDate(updatedAt);
};

export const extractUsersFromRoles = (roles: RolesManyToMany) => {
    const combinedUsers: Array<UserFromAPI> = [];

    if (!roles) {
        return combinedUsers;
    }

    roles.forEach((role) => {
        const userRoles = role.directus_roles_id.users;

        if (userRoles) {
            userRoles.forEach((user) => {
                if (user.status === "active") {
                    combinedUsers.push(user);
                }
            });
        }
    });

    return combinedUsers;
};
