import {
    Paper,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Box,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    Divider,
} from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { CustomEventType } from "../../utils/types";
import { useCreateRecord } from "../../../../api/useCreateRecord";
import { useUpdateRecord } from "../../../../api/useUpdateRecord";
import { Delete, Edit, SelectAll, ClearAll } from "@mui/icons-material";
import { TechnicianSelector } from "./TechnicianSelector";
import { useDeleteRecords } from "../../../../api/useDeleteRecords";

type SelectedEventsActionsProps = {
    selectedEvents: Array<CustomEventType>;
    users: any;
    setSelectedEvents: (events: Array<CustomEventType>) => void;
    selectMode: boolean;
    setSelectMode: (value: boolean) => void;
    filteredEvents: Array<CustomEventType>;
    resources: any;
};

export const SelectedEventsActions = ({
    selectedEvents,
    users,
    setSelectedEvents,
    selectMode,
    setSelectMode,
    filteredEvents,
    resources,
}: SelectedEventsActionsProps) => {
    const [showUsers, setShowUsers] = useState(false);
    const [numberOfCopies, setNumberOfCopies] = useState<number | undefined>(1);
    const [selectedDays, setSelectedDays] = useState<Array<number | "same">>([]);
    const [selectedResource, setSelectedResource] = useState<number | null>(null);
    const createSchedule = useCreateRecord();
    const deleteRecords = useDeleteRecords();
    const updateRecord = useUpdateRecord();

    const weekDays = [
        { value: "same", label: "Mesmo dia" },
        { value: 1, label: "Segunda-Feira" },
        { value: 2, label: "Terça-Feira" },
        { value: 3, label: "Quarta-Feira" },
        { value: 4, label: "Quinta-Feira" },
        { value: 5, label: "Sexta-Feira" },
        { value: 6, label: "Sabado" },
        { value: 0, label: "Domingo" },
    ] as const;

    if (!selectMode) {
        return null;
    }
    const isEventSelected = selectedEvents.length > 0;

    const handleSelectAll = () => {
        setSelectedEvents(filteredEvents);
    };

    const handleCopy = async (userIds?: Array<string>) => {
        const copies = numberOfCopies || 1;
        const items = selectedEvents.flatMap((event) =>
            Array.from({ length: copies }).flatMap(() =>
                (userIds || [event.userId]).flatMap((userId) => {
                    // If no days selected, use same day
                    const daysToUse = selectedDays.length === 0 ? ["same"] : selectedDays;

                    return daysToUse.map((selectedDay) => {
                        let start = new Date(event.start);
                        let end = new Date(event.end);

                        if (selectedDay !== "same" && typeof selectedDay === "number") {
                            const currentDay = start.getDay();
                            const daysToAdd = (selectedDay - currentDay + 7) % 7;
                            start = new Date(start.setDate(start.getDate() + daysToAdd));
                            end = new Date(end.setDate(end.getDate() + daysToAdd));
                        }

                        return {
                            clinic_id: event.clinicId,
                            start_date: start,
                            end_date: end,
                            user: userId,
                            services: {
                                create:
                                    event.services?.map((service) => ({
                                        schedules_id: "+",
                                        services_id: { id: service },
                                    })) || [],
                                update: [],
                            },
                            notes: event.notes,
                            room: selectedResource || event.room,
                        };
                    });
                })
            )
        );

        await createSchedule.mutateAsync({
            entity: "schedules",
            data: items,
        });

        setShowUsers(false);
        setNumberOfCopies(1);
        setSelectMode(false);
        setSelectedEvents([]);
        setSelectedDays([]);
        setSelectedResource(null);
    };

    const handleDelete = async () => {
        const idsToDelete = selectedEvents.map((event) => event.id);

        await deleteRecords.mutateAsync({
            entity: "schedules",
            ids: idsToDelete,
        });

        setSelectMode(false);
        setSelectedEvents([]);
    };

    const handleUpdateTechnician = async (userIds: Array<string>) => {
        if (userIds.length !== 1) {
            return;
        }

        for (const event of selectedEvents) {
            await updateRecord.mutateAsync({
                entity: "schedules",
                id: event.id,
                data: {
                    user: userIds[0],
                },
            });
        }

        setSelectMode(false);
        setSelectedEvents([]);
        setShowUsers(false);
    };

    const handleResourceChange = (event: any) => {
        setSelectedResource(event.target.value as number);
    };

    return (
        <Paper
            elevation={2}
            sx={{
                position: "fixed",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
                width: 300,
                p: 2,
            }}
        >
            <MenuList sx={{ p: 0 }}>
                <Box mb={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="copy-to-day-label">Copiar para dias</InputLabel>
                        <Select
                            labelId="copy-to-day-label"
                            multiple
                            value={selectedDays}
                            label="Copiar para dias"
                            onChange={(e) => {
                                const value = e.target.value as Array<number | "same">;
                                setSelectedDays(value);
                            }}
                            renderValue={(selected) =>
                                selected.map((day) => weekDays.find((d) => d.value === day)?.label).join(", ")
                            }
                        >
                            {weekDays.map((day) => (
                                <MenuItem key={day.value} value={day.value}>
                                    {day.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {typeof numberOfCopies !== "undefined" ? (
                        <TextField
                            label="Número de cópias"
                            type="number"
                            size="small"
                            fullWidth
                            value={numberOfCopies || ""}
                            placeholder="1"
                            onChange={(e) => {
                                const value = e.target.value === "" ? 0 : parseInt(e.target.value);
                                setNumberOfCopies(value);
                            }}
                            inputProps={{ min: 1 }}
                            sx={{ mt: 4 }}
                        />
                    ) : null}
                </Box>
                <Box mb={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="copy-to-resource-label">Copiar para sala</InputLabel>
                        <Select
                            labelId="copy-to-resource-label"
                            value={selectedResource || ""}
                            label="Copiar para sala"
                            onChange={handleResourceChange}
                        >
                            <MenuItem value={0}>Manter a mesma sala</MenuItem>
                            {resources.map((resource: any) => (
                                <MenuItem key={resource.id} value={resource.id}>
                                    {resource.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {!showUsers ? (
                    [
                        <MenuItem key="copy" onClick={() => handleCopy()} disabled={!isEventSelected} sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <ContentCopy color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Copiar com o mesmo técnico</ListItemText>
                        </MenuItem>,
                        <MenuItem
                            key="different-tech"
                            onClick={() => {
                                setNumberOfCopies(1);
                                setShowUsers(true);
                            }}
                            disabled={selectedEvents.length === 0}
                            sx={{ pl: 1 }}
                        >
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <PersonIcon color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Copiar com técnico diferente</ListItemText>
                        </MenuItem>,
                        <MenuItem
                            key="update-tech"
                            onClick={() => {
                                setNumberOfCopies(undefined);
                                setShowUsers(true);
                            }}
                            disabled={!isEventSelected}
                            sx={{ pl: 1 }}
                        >
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <Edit color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Atualizar técnico</ListItemText>
                        </MenuItem>,
                        <MenuItem key="delete" onClick={handleDelete} disabled={!isEventSelected} sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <Delete color="error" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Apagar eventos selecionados</ListItemText>
                        </MenuItem>,
                        <MenuItem key="select-all" onClick={handleSelectAll} sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <SelectAll color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Selecionar todos os eventos</ListItemText>
                        </MenuItem>,
                        <MenuItem key="clear-select-all" onClick={() => setSelectedEvents([])} sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <ClearAll color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Limpar seleção de eventos</ListItemText>
                        </MenuItem>,
                    ]
                ) : (
                    <Box p={2}>
                        <TechnicianSelector
                            users={users}
                            onSelect={(userIds) => (numberOfCopies ? handleCopy(userIds) : handleUpdateTechnician(userIds))}
                            mode={numberOfCopies ? "copy" : "update"}
                        />
                        <Button
                            fullWidth
                            color="secondary"
                            size="small"
                            onClick={() => {
                                setShowUsers(false);
                                setNumberOfCopies(1);
                            }}
                            sx={{ mt: 1 }}
                        >
                            Cancel
                        </Button>
                    </Box>
                )}
            </MenuList>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                        setSelectMode(false);
                        setSelectedEvents([]);
                    }}
                >
                    Cancelar
                </Button>
            </Box>
        </Paper>
    );
};
