import { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { FormatedField } from "../../../types/fields.types";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { CalendarEvent } from "../../../components/calendar/calendar.types";
import { getItems } from "../../../api/items";
import {  endOfDay } from "date-fns";

type SessionStatusFieldProps = {
  field: FormatedField;
  value: string;
  event: CalendarEvent | undefined;
  setPreviewIsOpen: (value: boolean) => void;
  refetchSessions: () => void;
};

export const SessionStatusField = ({
  field,
  value,
  event,
  setPreviewIsOpen,
  refetchSessions,
}: SessionStatusFieldProps) => {
  const [inputValue, setInputValue] = useState<string | null>(value);
  const updateStatus = useUpdateRecord();

  const handleChange = async (changeEvent: SelectChangeEvent) => {
    const eventId = event?.id;
    
    const statusValue = changeEvent.target.value;

    const response = await updateStatus.mutateAsync({
      entity: "sessions",
      id: eventId,
      data: {
        status: statusValue,
      },
    });

    const hasAnotherSessionToday = event ? await checkIfHasAnotherSessionToday(statusValue, event) : null;

    if (hasAnotherSessionToday) {
      await updateStatus.mutateAsync({
        entity: "sessions",
        id: hasAnotherSessionToday.id,
        data: {
          status: "awaiting_in_clinic",
        },
      });
    }

    if (response.id) {
      setPreviewIsOpen(false);
      refetchSessions();
    }

    setInputValue(statusValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Estado da sessão</InputLabel>
      <Select value={inputValue || ""} onChange={handleChange} label="Estado da sessão" sx={{ height: 56 }}>
        {field.settings?.options?.choices?.map((choice) => {
          return (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const checkIfHasAnotherSessionToday = async (statusValue: string, event: CalendarEvent) => {
  if (statusValue === "done") {
    const treatmentId = event?.treatment?.id;

    const hasAnotherSessionToday = await getItems("sessions", "*.*", {
      filter: {
        _and: [
          { start_date: { _gte: event?.start ? new Date(event.start) : undefined } },
          { start_date: { _lte: event?.start ? endOfDay(new Date(event.start)) : undefined } },
          { treatment: { _eq: treatmentId } },
          { id: { _neq: event?.id } },
        ],
      },
    });
    if (hasAnotherSessionToday.items.length > 0) {
      return hasAnotherSessionToday.items[0];
    }
  }
};
