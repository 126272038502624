import { Select, MenuItem, CircularProgress, FormControl, InputLabel } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { useRecords } from "../../../api/useRecords";

type RoomsDropdownProps = {
  entityForm: UseFormReturn;
  fieldName?: string;
  value?: string;
};

export const RoomsDropdown = ({ entityForm, fieldName = "room", value }: RoomsDropdownProps) => {
  const { data, status: roomsStatus } = useRecords("rooms", "*.*", { staleTime: Infinity, extraOptions: { sort: "title" } });

  if (roomsStatus === "loading") return <CircularProgress />;
  if (roomsStatus === "error") return <div>error...</div>;

  return (
    <Controller
      name={fieldName}
      control={entityForm.control}
      defaultValue={value || ""}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel>Sala</InputLabel>
          <Select
            {...field}
            fullWidth
            onChange={(event) => field.onChange(event.target.value)}
            sx={{ maxHeight: "56px" }}
            label="Sala"
          >
            {data?.items.map((room: any) => (
              <MenuItem key={room.id} value={room.id}>
                {room.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
