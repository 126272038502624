import {
  startOfDay,
  parseISO,
  eachDayOfInterval,
  min,
  max,
  format,
  differenceInHours,
  differenceInMinutes,
  endOfMonth,
  endOfYear,
  isWeekend,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { TimeOff, WorkLog, Totals } from "../types";
import { utcToZonedTime } from "date-fns-tz";
import { TIMEZONE } from "../../../utils/constants";
import { convertToHours } from "./general";
import { getPortugalHolidays } from "../../../api/usePortugalHolidays";

export const calculateTimeOffTotals = (entries: Array<TimeOff>) => {
  const totals = entries.reduce(
    (acc, entry) => {
      const start = startOfDay(parseISO(entry.start_date));
      const end = startOfDay(parseISO(entry.end_date));
      const daysInRange = eachDayOfInterval({ start, end }).length;

      if (entry.type === "holiday") {
        acc.holidayDays += daysInRange;
      } else if (entry.type === "sick_leave") {
        acc.sickLeaveDays += daysInRange;
      }

      return acc;
    },
    { holidayDays: 0, sickLeaveDays: 0 }
  );

  return {
    totalHolidays: totals.holidayDays,
    totalSickLeaveDays: totals.sickLeaveDays,
  };
};

export const calculateDayStats = (logs: WorkLog[]) => {
  if (logs.length === 0) return { timeStart: "", timeEnd: "", workHours: 0, clientsHours: 0, timeOffHours: 0 };

  const timeStart = min(logs.map((entry) => parseISO(entry.start_date)));
  const timeEnd = max(logs.map((entry) => parseISO(entry.end_date)));

  const workTotals = calculateHoursTotals(logs);

  return {
    timeStart: format(timeStart, "HH:mm"),
    timeEnd: format(timeEnd, "HH:mm"),
    workHours: workTotals.schedules,
    clientsHours: workTotals.clients,
  };
};

export const calculateTotals = (workLogs: WorkLog[], timeOff: TimeOff[]): Totals => {
  const workTotals = calculateHoursTotals(workLogs);
  const { totalHolidays, totalSickLeaveDays } = calculateTimeOffTotals(timeOff);

  return {
    totalWorkHours: workTotals.schedules,
    effectiveWorkHours: workTotals.schedules,
    totalClientHours: workTotals.clients,
    totalHolidays: totalHolidays,
    totalSickLeaveDays: totalSickLeaveDays,
  };
};

export const calculateHours = (start: string, end: string) => {
  const startDate = utcToZonedTime(parseISO(start), TIMEZONE);
  const endDate = utcToZonedTime(parseISO(end), TIMEZONE);
  const hours = differenceInHours(endDate, startDate);
  const minutes = differenceInMinutes(endDate, startDate) % 60;
  return `${hours}h ${minutes}m`;
};

export const calculateHoursTotals = (entries: any[]) => {
  const types = ["schedules", "clients"];
  return types.reduce((totals: Record<string, number>, type) => {
    totals[type] = entries
      .filter((entry) => entry.type === type)
      .reduce((sum, entry) => sum + convertToHours(calculateHours(entry.start_date, entry.end_date)), 0);
    return totals;
  }, {});
};

type CalculateTotalValueServiceProvider = {
  effectiveWorkHours: number;
  valuePerHour: number;
  totalClientHours: number;
  valuePerClient: number;
};

export const calculateTotalValueServiceProvider = ({
  effectiveWorkHours,
  valuePerHour,
  totalClientHours,
  valuePerClient,
}: CalculateTotalValueServiceProvider) => {
  return effectiveWorkHours * valuePerHour + totalClientHours * valuePerClient;
};

export const calculateExpectedWorkingHours = async (date: Date) => {
  const holidays = await getPortugalHolidays(date);

  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  const startOfYearDate = startOfYear(date);
  const endOfYearDate = endOfYear(date);
  const currentMonthEndDate = endOfMonth(date);

  const monthlyDays = eachDayOfInterval({ start: startDate, end: endDate });
  const yearlyDays = eachDayOfInterval({ start: startOfYearDate, end: endOfYearDate });
  const yearlyDaysUpToCurrentMonth = eachDayOfInterval({ start: startOfYearDate, end: currentMonthEndDate });

  let monthlyWorkingDays = 0;
  let yearlyWorkingDays = 0;
  let yearlyWorkingDaysUpToCurrentMonth = 0;

  for (const day of monthlyDays) {
    if (!isWeekend(day)) {
      monthlyWorkingDays++;
    }
  }

  for (const day of yearlyDays) {
    if (!isWeekend(day)) {
      yearlyWorkingDays++;
    }
  }

  for (const day of yearlyDaysUpToCurrentMonth) {
    if (!isWeekend(day)) {
      yearlyWorkingDaysUpToCurrentMonth++;
    }
  }

  let monthlyHolidaysHours = 0;
  let yearlyHolidaysHours = 0;
  let yearlyHolidaysHoursUpToCurrentMonth = 0;

  for (const holiday of holidays) {
    const holidayDate = new Date(holiday.date);
    if (holidayDate >= startDate && holidayDate <= endDate && !isWeekend(holidayDate)) {
      monthlyHolidaysHours += 8;
    }
    if (holidayDate >= startOfYearDate && holidayDate <= endOfYearDate && !isWeekend(holidayDate)) {
      yearlyHolidaysHours += 8;
    }
    if (holidayDate >= startOfYearDate && holidayDate <= currentMonthEndDate && !isWeekend(holidayDate)) {
      yearlyHolidaysHoursUpToCurrentMonth += 8;
    }
  }

  const monthlyExpectedHours = monthlyWorkingDays * 8 - monthlyHolidaysHours;
  const yearlyExpectedHours = yearlyWorkingDays * 8 - yearlyHolidaysHours;
  const totalUpToCurrentMonth = yearlyWorkingDaysUpToCurrentMonth * 8 - yearlyHolidaysHoursUpToCurrentMonth;

  return {
    monthlyExpectedHours,
    yearlyExpectedHours,
    totalUpToCurrentMonth,
  };
};
