import { FormatedFields } from "../types/fields.types";
import { UnknownItems } from "../types/items.types";
import { removeFields } from "./formatFields";
import { FieldsToRemoveType } from "./formatFields";

export type Group = {
    groupName: string;
    groupId: string;
    fields: FormatedFields;
};
export type Groups = Array<Group>;

export function formatGroups(fields: FormatedFields, item: UnknownItems, type: string) {
    let fieldsToRemove: FieldsToRemoveType = {
        fieldNamesToRemove: [],
    };
    if (type === "update") {
        fieldsToRemove.fieldNamesToRemove = ["user_created", "user_updated", "sort", "date_updated", "id"];
    } else {
        fieldsToRemove.fieldNamesToRemove = ["user_updated", "sort"];
    }

    const newFields = removeFields(fields, fieldsToRemove);

    if (!newFields) return { type: "groups", groups: [] };

    const groups: Groups = newFields.reduce((acumulater, current) => {
        if (current.settings.isGroup) {
            acumulater.push({
                groupName: current.headerName || "",
                groupId: current.field,
                fields: [],
            });
        }
        return acumulater;
    }, [] as Groups);

    newFields.forEach((field) => {
        groups.forEach((group) => {
            if (field.settings.group === group.groupId) {
                group.fields.push(field);
            }
        });
    });

    return { type: "groups", groups: groups };
}
