import { LinearProgress, Box } from "@mui/material";
import { useRecord } from "../../api/useRecord";
import { createContext } from "react";
import { FormatedFields } from "../../types/fields.types";
import { DetailHeader } from "../detailHeader/DetailHeader";
import { Groups } from "../groups/Groups";
import { Collection } from "../../types/collections.types";
import { Actions } from "../actionsMenu/ActionsMenu";
import { UseFormReturn, useForm } from "react-hook-form";
import { FormFields } from "../entityForm/EntityForm";
import { useParams } from "react-router-dom";
import { useFields } from "../../api/useFields";
import { UseRecordsOptions } from "../../api/useRecords";
import { useCollection } from "../../api/useCollection";

type RecordProps = {
    header?: React.ReactNode | "no_header";
    body: React.ReactNode;
    entity: string;
    type: "detail" | "update" | "create";
    fieldsQuery?: string;
    actions?: Actions;
    options?: UseRecordsOptions;
    idFromProps?: string | number | null;
};

export type RecordContextType = {
    entity: string;
    formatedFields: FormatedFields;
    id?: string | number | null;
    item?: any;
    type: "detail" | "update" | "create";
    collection: Collection;
    entityForm: UseFormReturn;
    options?: UseRecordsOptions;
};

export const RecordContext = createContext<RecordContextType | null>(null);

export const Record = ({ entity, header, body, type, fieldsQuery, actions, options, idFromProps }: RecordProps) => {
    const { id: idFromParams } = useParams();
    const id = idFromProps ? idFromProps : idFromParams;

    const { data: collection, status: statusCollection } = useCollection(entity);
    const { formatedFields, status: fieldsStatus } = useFields(entity, options);
    const { data, status } = useRecord(entity, fieldsQuery, id);

    const entityForm = useForm<FormFields>();

    if (status === "loading" || fieldsStatus === "loading" || statusCollection === "loading")
        return <LinearProgress color="secondary" />;
    if (status === "error" || fieldsStatus === "error" || statusCollection === "error") return <div>error</div>;
    const recordContext = { ...data, collection, formatedFields, entityForm, type, options };

    return (
        <RecordContext.Provider value={recordContext}>
            <Box flexDirection="column" flex={1}>
                {header === "no_header" ? null : header || <DetailHeader collection={collection} actions={actions} />}
                {body || <Groups />}
            </Box>
        </RecordContext.Provider>
    );
};
