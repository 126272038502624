import { useState } from "react";
import { Box, ListItem, ListItemButton, ListItemText, IconButton, Collapse, Typography, ListItemIcon } from "@mui/material";
import { ArrowBack, ExpandLess, ExpandMore, ListAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ProcessCategory, ProcessParentCategory } from "../types";
import { useRecords } from "../../api/useRecords";

export const ProcessesMenuItems = () => {
    const navigate = useNavigate();

    const { data: processParentCategories, status: processParentCategoriesStatus } = useRecords(
        "processes_parent_category",
        "*.*"
    );

    if (processParentCategoriesStatus === "loading") return null;

    if (!processParentCategories) return null;

    return (
        <Box>
            <ListItemButton onClick={() => navigate("/sessions")}>
                <ListItemIcon>
                    <ArrowBack sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Voltar" sx={{ color: "white" }} />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/processes")}>
                <ListItemIcon>
                    <ListAlt sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Biblioteca" sx={{ color: "white" }} />
            </ListItemButton>
            {processParentCategories.items.map((item: ProcessParentCategory) => (
                <MenuItemWithCategories key={item.id} item={item} navigate={navigate} />
            ))}
        </Box>
    );
};

type MenuItemWithCategoriesProps = {
    item: ProcessParentCategory;
    navigate: (path: string) => void;
};

const MenuItemWithCategories = ({ item, navigate }: MenuItemWithCategoriesProps) => {
    const [open, setOpen] = useState(false);

    const handleExpandClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setOpen(!open);
    };

    return (
        <Box>
            <ListItem
                disablePadding
                secondaryAction={
                    <IconButton
                        onClick={handleExpandClick}
                        sx={{
                            "&:hover": { backgroundColor: "transparent" },
                            color: "white",
                        }}
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                }
            >
                <ListItemButton onClick={() => navigate(`/processes/parentcategory/${item.id}`)}>
                    <ListItemText primary={item.name} sx={{ color: "white" }} />
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4 }}>
                    {item.categories?.map((category: ProcessCategory) => (
                        <ListItem key={category.id} disablePadding>
                            <ListItemButton onClick={() => navigate(`/processes/parentcategory/${item.id}/category/${category.id}`)}>
                                <ListItemText primary={category.name} sx={{ color: "white" }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
};
