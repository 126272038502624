import { utcToZonedTime } from "date-fns-tz";
import { parseISO, format, isSameDay } from "date-fns";
import { TIMEZONE } from "../../../utils/constants";
import { TimeOff } from "../types";

export const formatToLisbonTime = (dateString: string) => {
  const date = utcToZonedTime(parseISO(dateString), TIMEZONE);
  return format(date, "yyyy-MM-dd'T'HH:mm");
};

export const convertToHours = (timeString: string): number => {
  const [hours, minutes] = timeString.split("h ");
  return parseInt(hours) + parseInt(minutes) / 60;
};

export const getDaysArray = (start: Date, end: Date) => {
  const arr = Array<Date>();
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const createTimeOffEntries = (timeOffRaw: any[]): TimeOff[] => {
  return timeOffRaw.flatMap((entry) => {
    const start = parseISO(entry.start_date);
    const end = parseISO(entry.end_date);

    if (isSameDay(start, end)) {
      return [entry];
    }

    return getDaysArray(start, end).map((day) => ({
      ...entry,
      start_date: format(day, "yyyy-MM-dd'T'10:00:00"),
      end_date: format(day, "yyyy-MM-dd'T'18:00:00"),
    }));
  });
};
