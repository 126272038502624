import { useContext, useEffect, useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { getMenuItems } from "../../utils/getMenuItems";
import { useStore } from "../../store/store";
import { CollectionsContext } from "../root/CollectionsProvider";
import { UserFromAPI } from "../../types/auth.types";

export type MenuItem = {
    id: string;
    name: string;
    icon?: JSX.Element;
    link: string;
    collection: string;
};

export const menuItemsStyle = {
    color: "#fff",
    borderRadius: 1,
    ":hover": {
        backgroundColor: "secondary.main",
    },
};

type MenuItemsProps = {
    items: MenuItem[];
    user: UserFromAPI;
};

export const MenuItems = ({ items, user }: MenuItemsProps) => {
    const collections = useContext(CollectionsContext);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<number | null>(null);
    const { menu } = useStore();

    let location = useLocation();

    useEffect(() => {
        if (!collections) return;

        const formatedMenuItems = getMenuItems(collections.collections, items, user);

        setMenuItems(formatedMenuItems);
    }, [collections, items]);

    useEffect(() => {
        const entity = location.pathname.split("/")[1];

        const index = menuItems.findIndex((item) => item.collection === entity);
        if (index !== selectedItem) {
            setSelectedItem(index);
        }
    }, [location.pathname, menuItems, selectedItem]);

    return (
        <List>
            {menuItems.map((item, index) => {
                return (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton
                            component={Link}
                            to={item.link}
                            onClick={() => {
                                setSelectedItem(index);
                                // if mobile, close menu
                                menu.setIsOpen(false);
                            }}
                            sx={{
                                backgroundColor: selectedItem === index ? "secondary.main" : "transparent",
                                ...menuItemsStyle,
                            }}
                        >
                            {item.icon && <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>}
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};
