import { Info, EuroSymbol } from "@mui/icons-material";

export const tabs = [
	{
		icon: <Info />,
		entity: "detail",
		label: "Detalhes",
	},
	{
		icon: <EuroSymbol />,
		entity: "orders",
		label: "Compras",
	}
];
