import { useField } from '../api/useField';

interface Choice {
  text: string;
  value: string;
}

export function useFieldSelectOptions(entity: string, field: string): Choice[] {
  const { formatedField } = useField(entity, field);
  
  const choices = formatedField?.settings?.options?.choices || [];
  return choices.filter((choice): choice is Choice => 
    typeof choice.text === 'string' && typeof choice.value === 'string'
  );
}
