import { orderBy } from "lodash";
import { ROLES } from "../../../utils/constants";
import { EmployeeSummary, ExportSettings } from "../types";

const rolesMapping = [
  {
    role: "admin",
    id: ROLES.admin,
  },
  {
    role: "shilan",
    id: ROLES.shilan,
  },
  {
    role: "technical_director",
    id: ROLES.technical_director,
  },
  {
    role: "reception_director",
    id: ROLES.reception_director,
  },
  {
    role: "neurofeedback_director",
    id: ROLES.neurofeedback_director,
  },
  {
    role: "nfb_techncians",
    id: ROLES.nfb_techncians,
  },
  {
    role: "nfb_and_theurapist",
    id: ROLES.nfb_and_theurapist,
  },
  {
    role: "theurapist",
    id: ROLES.theurapist,
  },
  {
    role: "recepcionist",
    id: ROLES.recepcionist,
  },
  {
    role: "other",
    id: ROLES.other,
  },
];

export const getExportData = (employeeSummaries: EmployeeSummary[], type: "salaries" | "subsidies") => {
  const roleOrder = new Map(rolesMapping.map((role, index) => [role.id, index]));
  const PUSH_TO_END = 999999;
  const SUBSIDY_AGREEMENT_TYPES = ["term_contract", "full_term"];
  const sortedSummaries = orderBy(employeeSummaries, (summary) => {
    const roleId = summary.user.role?.id ?? summary.user.role
    return roleOrder.get(roleId) ?? PUSH_TO_END;
  });

  const exportSettings = {
    data: [],
    columns: [],
  } as ExportSettings;

  const mapToExportFormat = (summary: EmployeeSummary) => {
    const { first_name, last_name } = summary.user;
    const { amount_receipt_to_transfer, subsidy, staff_agreement } = summary.payment || {};
    const { agreement_type, iban, nif } = staff_agreement || {};

    const fullName = `${first_name} ${last_name}`;
    const truncatedName = fullName.substring(0, 27);

    const description =
      agreement_type && SUBSIDY_AGREEMENT_TYPES.includes(agreement_type)
        ? `SALARIO ${first_name} ${last_name}`
        : `PSERVICOS ${first_name} ${last_name}`;

    // Smart truncation that tries to break at word boundary
    const smartTruncate = (str: string, maxLength: number) => {
      if (str.length <= maxLength) return str;
      const truncated = str.substring(0, maxLength);
      const lastSpace = truncated.lastIndexOf(" ");
      return lastSpace > 0 ? truncated.substring(0, lastSpace) : truncated;
    };

    const truncatedDescription = smartTruncate(description, 25);

    return {
      name: truncatedName,
      nif: nif || "",
      "": "", // Empty column
      amount_to_pay: amount_receipt_to_transfer || "",
      subsidy: subsidy || "",
      iban: iban || "",
      description: truncatedDescription,
    };
  };

  if (type === "salaries") {
    const data = sortedSummaries
      .filter((summary) => {
        // Filter out entries without agreement type
        if (!summary.payment?.staff_agreement?.agreement_type) return false;
        // Filter out entries without amount
        const amount = summary.payment?.amount_receipt_to_transfer;
        return amount !== null && amount !== undefined;
      })
      .map(mapToExportFormat);

    const columns = [
      { key: "iban", width: 30, header: "IBAN" },
      { key: "name", width: 20, header: "Name" },
      { key: "", width: 20, header: "" },
      { key: "amount_to_pay", width: 15, header: "Amount to Pay" },
      { key: "description", width: 30, header: "Description" },
    ];

    exportSettings.data = data;
    exportSettings.columns = columns;

    return exportSettings;
  }

  if (type === "subsidies") {
    const subsidiesData = sortedSummaries
      .filter((summary) => {
        const agreementType = summary.payment?.staff_agreement?.agreement_type;
        return agreementType && SUBSIDY_AGREEMENT_TYPES.includes(agreementType);
      })
      .map(mapToExportFormat);

    const subsidyColumns = [
      { key: "nif", width: 20, header: "NIF Colaborador" },
      { key: "name", width: 20, header: "Nome do Colaborador" },
      { key: "", width: 20, header: "" },
      { key: "subsidy", width: 15, header: "Valor" },
    ];

    exportSettings.data = subsidiesData;
    exportSettings.columns = subsidyColumns;

    return exportSettings;
  }

  return exportSettings;
};
