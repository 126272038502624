import { AccountTree, HistoryEdu, School, SmartToy } from "@mui/icons-material";
import { ProcessType } from "../types";
import { Tooltip } from "@mui/material";

type ProcessesTypeIconProps = {
    type: ProcessType;
    size?: "large" | "small" | "medium";
};
export const ProcessesTypeIcon = ({ type, size }: ProcessesTypeIconProps) => {
    const iconSize = size || "large";
    if (type === "process")
        return (
            <Tooltip title="Processo">
                <AccountTree fontSize={iconSize} color="secondary" />
            </Tooltip>
        );
    if (type === "template")
        return (
            <Tooltip title="Template">
                <HistoryEdu fontSize={iconSize} color="secondary" />
            </Tooltip>
        );
    if (type === "knowledge")
        return (
            <Tooltip title="Conhecimento">
                <School fontSize={iconSize} color="secondary" />
            </Tooltip>
        );
    if (type === "automation")
        return (
            <Tooltip title="Automação">
                <SmartToy fontSize={iconSize} color="secondary" />
            </Tooltip>
        );
    return null;
};
