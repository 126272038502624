import { useMemo } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useParams } from "react-router-dom";
import { Process } from "../types";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

type ProcessesBreadcrumbsProps = {
    process: Process;
};

export const ProcessesBreadcrumbs = ({ process }: ProcessesBreadcrumbsProps) => {
    const location = useLocation();
    const { parentcategoryid, categoryid, processid } = useParams();

    const breadcrumbs = useMemo(() => {
        const breadcrumbItems = [];

        breadcrumbItems.push(
            <Link component={RouterLink} underline="hover" key="home" color="inherit" to="/processes">
                Biblioteca
            </Link>
        );

        if (parentcategoryid && process?.process_category?.process_parent_category) {
            breadcrumbItems.push(
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="parentCategory"
                    color="inherit"
                    to={`/processes/parentcategory/${parentcategoryid}`}
                >
                    {process.process_category.process_parent_category.name}
                </Link>
            );
        }

        if (categoryid && process?.process_category) {
            breadcrumbItems.push(
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="category"
                    color="inherit"
                    to={`/processes/parentcategory/${parentcategoryid}/category/${categoryid}`}
                >
                    {process.process_category.name}
                </Link>
            );
        }

        if (processid) {
            breadcrumbItems.push(
                <Typography key="process" color="text.primary" variant="caption">
                    {process.title}
                </Typography>
            );
        }

        return breadcrumbItems;
    }, [location, parentcategoryid, categoryid, processid, process]);

    return (
        <Stack spacing={2}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ fontSize: "0.8rem" }}>
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
};
