import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Box, Typography } from "@mui/material";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";

import { formatCurrency } from "../../utils/formatCurrency";

export const BankTransactionDetailHeader = () => {
    const recordContext = useContext(RecordContext);

    if (!recordContext) return null;
    const { category, document_date, amount } = recordContext.item;

    return (
        <DetailHeader collection={recordContext.collection}>
            <Box minWidth={300} display="flex" columnGap={10}>
                <Box>
                    <Typography variant="h6" color="secondary">
                        Movimento Bancário
                    </Typography>
                    <Box display="flex" columnGap={4} alignItems="flex-start">
                        {category?.name && <Typography variant="h5">{category?.name}: </Typography>}    
                        <Typography variant="h5" sx={{ fontWeight: 0 }}>
                            {formatCurrency(amount, ".")}
                        </Typography>
                    </Box>
                    <Typography variant="body1">{document_date}</Typography>
                </Box>
            </Box>
        </DetailHeader>
    );
};
