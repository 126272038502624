import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate } from "../types/records.types";
import { MutationOptions } from "../types/mutation.types";
import { useStore } from "../store/store";

export type RecordsToDelete = {
    entity: string;
    ids: Array<number | number>;
    doStuffAfterDelete?: () => void;
};

export const useDeleteRecords = (options?: MutationOptions) => {
    const queryClient = useQueryClient();
    const { notifications } = useStore();

    return useMutation<any, ErrorResponsesCreateOrUpdate, RecordsToDelete, any>({
        mutationFn: (record) => deleteRecords(record),
        onSuccess(data, variables) {
            queryClient.invalidateQueries({
                predicate(query: any) {
                    return query.queryKey[0].startsWith(options?.queryInvalidatePrefix || variables.entity);
                },
            });

            if (variables.doStuffAfterDelete) {
                variables.doStuffAfterDelete();
            }
        },
        onError(error: ErrorResponsesCreateOrUpdate) {
            notifications.setNotification({
                isOpen: true,
                message: error.response.data.errors[0].message,
                severity: "error",
            });
            return error.response.data.errors[0].message;
        },
    });
};

const deleteRecords = async (records: RecordsToDelete) => {
    if (records?.ids?.length === 0 || !records?.entity) return;
    const deletedRecords = await api({
        method: "DELETE",
        url: `items/${records.entity}`,
        data: records.ids,
    });

    return deletedRecords.data.data;
};
