import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./staffAgreementstabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { staffAgreementsOptions } from "./staffAgreementOptions";
import { NotesComponent } from "../../components/notes/NotesComponent";

export const fieldsQuery = "*.*";

export const staffAgreementsRoutes = [
    {
        path: "/staff_agreements",
        element: (
            <Records entity="staff_agreements" fieldsQuery={fieldsQuery} options={staffAgreementsOptions} heightOfHeaders={190} />
        ),
    },
    {
        path: "/staff_agreements/:id",
        element: <Record entity="staff_agreements" type="detail" fieldsQuery={fieldsQuery} body={<DetailTabs tabs={tabs} />} />,
        children: [
            {
                path: "/staff_agreements/:id/detail",
                element: <Groups />,
            },
            {
                element: <NotesComponent />,
                path: "/staff_agreements/:id/notes",
            },
        ],
    },
    {
        path: "/staff_agreements/:id/update",
        element: (
            <Record
                entity="staff_agreements"
                type="update"
                body={<EntityForm groupsOptions={staffAgreementsOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/staff_agreements/create",
        element: (
            <Record
                entity="staff_agreements"
                type="create"
                body={<EntityForm groupsOptions={staffAgreementsOptions?.groupsOptions} />}
            />
        ),
    },
];
