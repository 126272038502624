export type formatClientNameProps = {
    firstName: string;
    lastName: string;
};

export const formatClientName = (firstName: string, lastName: string) => {
    const formattedClientName = firstName && lastName ? `${firstName} ${lastName}` : "";
    const clientName = formattedClientName.length > 10 ? `${formattedClientName.substring(0, 10)}...` : formattedClientName;

    return clientName;
};

export const calculateTransactionsTotalAmount = (transactions: Array<any>): number => {
    const total = transactions
        ? transactions.reduce((accumulator, transaction) => {
              const amount = parseFloat(transaction.amount);
              const result = accumulator + amount;
              return result;
          }, 0)
        : 0;
    return total;
};


export const isWithinTwentyPercentRange = (total: number, bankTransactionAmount: string): boolean => {
    // Calculate the 20% difference threshold
    const twentyPercentDifference = total * 0.2;

    // Calculate the lower and upper bounds for the 20% difference
    const lowerBound = total - twentyPercentDifference;
    const upperBound = total + twentyPercentDifference;

    // Check if the bank transaction amount is within the 20% range
    const isWithin20Percent = parseFloat(bankTransactionAmount) >= lowerBound && parseFloat(bankTransactionAmount) <= upperBound;

    // Return the result of the check
    return isWithin20Percent;
};