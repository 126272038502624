import { useMutation } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate } from "../types/records.types";
import { OrderHeader, OrderItem } from "../screens/orders/ordersGridTypes.types";
import { queryClient } from "../main";
import { useContext } from "react";
import { RecordContext } from "../components/record/Record";

export const useCreateOrder = () => {
    const recordContext = useContext(RecordContext);

    return useMutation<any, ErrorResponsesCreateOrUpdate, any, any>({
        mutationFn: (formData: any) => createOrder(formData),
        onSuccess(data, variables) {
            createOrderItems(data.id, variables);
            queryClient.invalidateQueries({
                predicate(query: any) {
                    return query.queryKey[0].startsWith("orders");
                },
            });
            recordContext?.entityForm.reset();
        },
    });
};

const createOrder = async (formData: any) => {
    const order = {
        treatment: formData.treatment?.id,
        payment_method: formData.payment_method,
        payment_status: formData.payment_status,
        order_date: formData.order_date,
        notes: formData.notes,
        bank_transaction: formData.bank_transaction?.id,
    } as OrderHeader;

    const response = await api({
        method: "POST",
        url: `items/orders`,
        data: order,
    });

    return response.data.data;
};

export const createOrderItems = async (id: number, formData: any) => {
    if (!formData.order_items) return;

    const orderItemsData = formData.order_items as OrderItem[];

    const orderItems = orderItemsData.map((item: OrderItem) => {
        return {
            order_id: id,
            service_variant: item.variantId,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount,
        };
    });

    const response = await api({
        method: "POST",
        url: `items/order_items`,
        data: orderItems,
    });

    return response.data.data;
};

export const createOrderItem = async (orderId: number, orderItem: OrderItem) => {
    const response = await api({
        method: "POST",
        url: `items/order_items`,
        data: {
            order_id: orderId,
            service_variant: orderItem.variantId,
            quantity: orderItem.quantity,
            price: orderItem.price,
            discount: orderItem.discount,
        },
    });

    return response.data.data;
};
