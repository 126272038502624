import { useState, useMemo, createContext } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { WorkLog, TimeOff } from "./types";
import { WorkLogModal } from "./components/WorkLogModal";
import { DaySummary } from "./components/DaySummary";
import { DateFilter } from "./components/DateFilter";
import { EmployeeSummary } from "./components/EmployeeSummary";
import { useRecords } from "../../api/useRecords";
import { startOfMonth, endOfMonth } from "date-fns";
import { ArrowBack } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { StaffWorkLogsContextType } from "./types";
import { SPECIAL_USERS } from "../../utils/constants";
import { calculateTotals } from "./utils/calculations";
import { useStaffWorkLogsFilter } from "./utils/filters";
import { getDaysArray } from "./utils/general";
import { useWorkLogs } from "./utils/useWorkLogs";

export const StaffWorkLogsContext = createContext<StaffWorkLogsContextType>({} as StaffWorkLogsContextType);

export const StaffWorkLogsDetail = () => {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const workLogForm = useForm();
  const { id: userId } = useParams() as { id: string };
  const { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth } = useStaffWorkLogsFilter();

  const startDate = startOfMonth(new Date(selectedYear, selectedMonth));
  const endDate = endOfMonth(new Date(selectedYear, selectedMonth));

  const { workLogs, timeOff, isLoading, isError, currentUser, isCurrentUserAdmin, thisYearHolidays } = useWorkLogs(
    startDate,
    endDate,
    userId
  );

  const { data } = useRecords("staff_agreements", "*.*", {
    extraOptions: {
      filter: {
        user: { id: { _eq: userId } },
      },
    },
  });

  const staffAgreement = data?.items[0];

  const { data: payments, status: paymentsStatus } = useRecords("staff_monthly_payments", "*.*", {
    extraOptions: {
      filter: {
        _and: [{ user: { _eq: userId } }, { year: { _eq: selectedYear } }, { month: { _eq: selectedMonth + 1 } }],
      },
    },
  });

  const toggleRowExpansion = (date: string) => {
    setExpandedRows((prev) => ({ ...prev, [date]: !prev[date] }));
  };

  const organizedData = useMemo(() => {
    const data: { [key: string]: { workLogs: WorkLog[]; timeOffs: TimeOff[] } } = {};
    getDaysArray(startDate, endDate).forEach((date) => {
      const dateString = format(date, "yyyy-MM-dd");
      data[dateString] = { workLogs: [], timeOffs: [] };
    });

    workLogs.forEach((log: WorkLog) => {
      const dateString = format(new Date(log.start_date), "yyyy-MM-dd");
      if (data[dateString]) data[dateString].workLogs.push(log);
    });

    timeOff.forEach((to: TimeOff) => {
      const dateString = format(new Date(to.start_date), "yyyy-MM-dd");
      if (data[dateString]) data[dateString].timeOffs.push(to);
    });

    return data;
  }, [workLogs, timeOff, startDate, endDate]);

  if (isLoading || paymentsStatus === "loading") {
    return <LinearProgress color="secondary" />;
  }

  if (isError || paymentsStatus === "error") {
    return <Typography>Error loading data</Typography>;
  }
  const totals = calculateTotals(workLogs, timeOff);
  const payment = payments?.items[0];

  const staffWorkLogsContext: StaffWorkLogsContextType = {
    selectedYear,
    selectedMonth,
    currentUser,
    isCurrentUserAdmin,
    thisYearHolidays,
    payment,
    totals,
    userId,
    staffAgreement,
    workLogForm,
    isModalOpen,
    setIsModalOpen,
  };

  return (
    <StaffWorkLogsContext.Provider value={staffWorkLogsContext}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <Paper sx={{ p: 2, mb: 2, display: "flex", justifyContent: "space-between", gap: 2 }}>
            <DateFilter
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              setSelectedYear={setSelectedYear}
              setSelectedMonth={setSelectedMonth}
            />
            {(isCurrentUserAdmin || SPECIAL_USERS.joana_director) && (
              <IconButton component={Link} to={`/staff-work-logs?year=${selectedYear}&month=${selectedMonth + 1}`}>
                <ArrowBack color="primary" />
              </IconButton>
            )}
          </Paper>
          <EmployeeSummary />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Data</TableCell>
                  <TableCell align="center">Hora Início</TableCell>
                  <TableCell align="center">Hora Fim</TableCell>
                  <TableCell align="center">Horas Trabalhadas</TableCell>
                  <TableCell align="center">Horas Clientes</TableCell>
                  <TableCell align="center">Detalhes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDaysArray(startDate, endDate).map((date) => {
                  const dateString = format(date, "yyyy-MM-dd");
                  return (
                    <DaySummary
                      key={dateString}
                      date={date}
                      expandedRows={expandedRows}
                      toggleRowExpansion={toggleRowExpansion}
                      dateString={dateString}
                      organizedData={organizedData}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <WorkLogModal />
      </LocalizationProvider>
    </StaffWorkLogsContext.Provider>
  );
};
