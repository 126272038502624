import { Grid, Select, MenuItem, FormControl, InputLabel, Divider, Button, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { useRecords } from "../../../api/useRecords";
import { useStore } from "../../../store/store";
import { getRelateServicesFromConstants } from "../../../utils/getRelatedServicesFromConstants";
import { getRoomIdsByType } from "../utils/scheduleUtils";

type SchedulesFiltersProps = {
    users: any;
    resources?: Array<any>;
};

export const SchedulesFilters = ({ resources }: SchedulesFiltersProps) => {
    const {
        schedules: { servicesSelected, setServicesSelected, eventTypeFilter, setEventTypeFilter, resourceIds, setResourceIds },
    } = useStore();

    const nfbRelatedServicesIds = getRelateServicesFromConstants("nfb");
    const psyRelatedServicesIds = getRelateServicesFromConstants("psy");

    const { data: services, status: servicesStatus } = useRecords("services", "*.*", {
        staleTime: Infinity,
        extraOptions: { filter: { id: { _neq: 41 } }, sort: "name" },
    });

    if (servicesStatus === "loading") return <CircularProgress />;
    if (servicesStatus === "error") return <div>error...</div>;

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginBottom: "20px" }}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Salas</InputLabel>
                    <Select
                        multiple
                        value={resourceIds}
                        onChange={(e) => {
                            const value = e.target.value as Array<string | number>;
                            if (value.includes("nfb_rooms")) {
                                const nfbRoomsIds = getRoomIdsByType(resources, "nfb");
                                setResourceIds(nfbRoomsIds);
                            } else if (value.includes("psy_rooms")) {
                                const psyRoomsIds = getRoomIdsByType(resources, "psy");
                                setResourceIds(psyRoomsIds);
                            } else {
                                setResourceIds(value.includes(0) ? [] : (value as Array<number>));
                            }
                        }}
                        label="Salas"
                    >
                        <MenuItem value={0}>Remover</MenuItem>
                        {resources?.map((resource: any) => (
                            <MenuItem key={resource.id} value={resource.id}>
                                {resource.title}
                            </MenuItem>
                        ))}
                        <Divider />

                        <MenuItem value={"nfb_rooms"}>
                            <Button fullWidth variant="contained" color="secondary">
                                Neurofeedback
                            </Button>
                        </MenuItem>
                        <MenuItem value={"psy_rooms"}>
                            <Button fullWidth variant="contained" color="secondary">
                                Psicologia
                            </Button>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Serviços</InputLabel>
                    <Select
                        multiple
                        value={servicesSelected}
                        onChange={(e) => {
                            const value = e.target.value as Array<string | number>;
                            if (value.includes("nfb_related")) {
                                setServicesSelected(nfbRelatedServicesIds);
                            } else if (value.includes("psy_related")) {
                                setServicesSelected(psyRelatedServicesIds);
                            } else {
                                setServicesSelected(value.includes(0) ? [] : (value as Array<number>));
                            }
                        }}
                        label="Serviços"
                        renderValue={(selected) =>
                            services?.items
                                .filter((service: any) => selected.includes(service.id))
                                .map((service: any) => service.name)
                                .join(", ")
                        }
                    >
                        <MenuItem value={0}>Remover</MenuItem>
                        {services?.items.map((service: any) => (
                            <MenuItem key={service.id} value={service.id as number}>
                                {service.name}
                            </MenuItem>
                        ))}
                        <MenuItem key={"admin"} value={9999}>
                            Backoffice
                        </MenuItem>
                        <Divider />

                        <MenuItem value={"nfb_related"}>
                            <Button fullWidth variant="contained" color="secondary">
                                Relacionado com Neurofeedback
                            </Button>
                        </MenuItem>
                        <MenuItem value={"psy_related"}>
                            <Button fullWidth variant="contained" color="secondary">
                                Relacionado com Psicologia
                            </Button>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Event Type</InputLabel>
                    <Select
                        value={eventTypeFilter}
                        onChange={(e) => setEventTypeFilter(e.target.value as string)}
                        label="Event Type"
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="schedule">Schedules</MenuItem>
                        <MenuItem value="time_off">Time Offs</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="center" gap={2}>
                <Button variant="contained" size="small" color="primary" component={Link} to="/schedules/add_multiple">
                    Adicionar Múltiplos
                </Button>
                <Button variant="contained" color="secondary" component={Link} to="/time_off/create">
                    Criar indisponibilidade
                </Button>
            </Grid>
        </Grid>
    );
};
