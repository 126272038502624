import { startOfWeek, subWeeks, endOfWeek, format, addDays } from "date-fns";
import { getRecords } from "../../../../api/useRecords";
import { Box, Button } from "@mui/material";
import { useStore } from "../../../../store/store";
import { useCreateRecord } from "../../../../api/useCreateRecord";

export const CopyEventsFromLastWeek = () => {
  const {
    schedules: { calendarFilters },
    notifications,
  } = useStore();

  const createSchedule = useCreateRecord();

  const handleCopyFromLastWeek = async () => {
    if (!calendarFilters?.dateRange?.start_date) return;

    const currentWeekStart = startOfWeek(new Date(calendarFilters.dateRange.start_date), { weekStartsOn: 1 });
    const lastWeekStart = subWeeks(currentWeekStart, 1);
    const lastWeekEnd = endOfWeek(lastWeekStart, { weekStartsOn: 1 });

    const lastWeekFilters = {
      _and: [
        {
          start_date: {
            _gte: format(lastWeekStart, "yyyy-MM-dd"),
          },
        },
        {
          end_date: {
            _lte: format(lastWeekEnd, "yyyy-MM-dd"),
          },
        },
        {
          _or: [
            {
              services: {
                services_id: {
                  _in: [33, 35, 39],
                },
              },
            },
          ],
        },
      ],
    };

    const schedules = await getRecords("schedules", "*,services.*.*,user.*.*", {
      extraOptions: { filter: lastWeekFilters },
    });

    const lastWeekEvents = schedules.items;
    const daysDiff = 7;

    const items = lastWeekEvents.map((event: any) => {
      const services = event.services?.length > 0 ? event.services : null;
      return {
        clinic_id: event.clinic_id,
        start_date: addDays(new Date(event.start_date), daysDiff),
        end_date: addDays(new Date(event.end_date), daysDiff),
        user: event.user.id,
        services: {
          create:
            services?.map((service: any) => {
              return {
                schedules_id: "+",
                services_id: { id: service.services_id.id },
              };
            }) || [],
          update: [],
        },
        notes: event.notes,
        room: event.room,
      };
    });

    const confirmCopy = window.confirm(`De certeza que quer copiar ${items.length} eventos da semana passada?`);
    if (!confirmCopy) return;

    try {
      if (items.length > 0) {
        await createSchedule.mutateAsync({
          entity: "schedules",
          data: items,
        });
      }
      notifications.setNotification({
        severity: "success",
        message: "Eventos copiados com sucesso",
        isOpen: true,
      });
    } catch (error: any) {
      notifications.setNotification({
        severity: "error",
        message: error?.response?.data?.errors[0]?.message,
        isOpen: true,
      });
    }
  };

  return (
    <Box>
      <Button color="secondary" size="small" onClick={handleCopyFromLastWeek} disabled={createSchedule.isLoading}>
        Copiar NFB da semana passada
      </Button>
    </Box>
  );
};
