import { TableRow, Typography } from "@mui/material";
import { getUserRowStyle, StyledTableCell, getHoursCellStyle } from "./styles";
import { workDays } from "../../utils/scheduleUtils";
import { MAX_WORK_HOURS } from "../../../../utils/constants";

type UserScheduleRowProps = {
    userName: string;
    userColor?: string;
    dailyTotals: Record<string, number>;
    weeklyTotal: number;
    categoryKey?: string;
};

export const UserScheduleRow = ({ userName, userColor, dailyTotals, weeklyTotal, categoryKey }: UserScheduleRowProps) => {
    return (
        <TableRow key={categoryKey ? `${categoryKey}-${userName}` : userName} sx={getUserRowStyle(userColor)}>
            <StyledTableCell className="name-column">
                <Typography variant="body2">{userName}</Typography>
            </StyledTableCell>
            {workDays.map((day) => {
                const hoursDay = dailyTotals[day];
                const maxHoursDay = MAX_WORK_HOURS.day;

                return (
                    <StyledTableCell key={day} className="data-column">
                        <Typography sx={getHoursCellStyle(hoursDay, maxHoursDay)} variant="body2">
                            {hoursDay.toFixed(1)}
                        </Typography>
                    </StyledTableCell>
                );
            })}
            <StyledTableCell className="data-column">
                <Typography sx={getHoursCellStyle(weeklyTotal, MAX_WORK_HOURS.week)} variant="body2">
                    {weeklyTotal.toFixed(1)}
                </Typography>
            </StyledTableCell>
        </TableRow>
    );
};
