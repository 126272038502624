import { ProcessesHome } from "./ProcessesHome";
import { ProcessesCategoriesList } from "./ProcessesCategoriesList";
import { ProcessDetail } from "./ProcessDetail";
import { ProcessesList } from "./ProcessesList";

export const fieldsQuery = "*.*";

export const processesRoutes = [
    {
        path: "/processes",
        element: <ProcessesHome />,
    },
    {
        path: "/processes/parentcategory/:parentcategoryid",
        element: <ProcessesCategoriesList />,
    },
    {
        path: "/processes/parentcategory/:parentcategoryid/category/:categoryid",
        element: <ProcessesList />,
    },
    {
        path: "/processes/parentcategory/:parentcategoryid/category/:categoryid/:processid",
        element: <ProcessDetail />,
    },
];
