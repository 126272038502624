import { useQuery } from "@tanstack/react-query";
import { startOfYear, endOfMonth } from "date-fns";
import { getRecords } from "../../../api/useRecords";
import { calculateExpectedWorkingHours, calculateHoursTotals } from "./calculations";
import { createWorklogs } from "./useWorkLogs";

export const useWorkedVsExpectedHours = (date: Date, userId: string, thisYearHolidays: number) => {
  return useQuery({
    queryKey: [`worked-vs-expected-hours-${userId}`, date],
    queryFn: () => getWorkedVsExpectedHours(date, userId, thisYearHolidays),
    staleTime: Infinity,
  });
};

export const getWorkedVsExpectedHours = async (date: Date, userId: string, thisYearHolidays: number) => {
  const expectedHours = await calculateExpectedWorkingHours(date);
  const schedules = await getRecords("schedules", "id,start_date,end_date,notes,user.*", {
    extraOptions: {
      filter: {
        start_date: { _gte: startOfYear(date).toISOString(), _lte: endOfMonth(date).toISOString() },
        user: { id: { _eq: userId } },
      },
      pageSize: -1,
    },
  });

  const workLogsNew = createWorklogs([], schedules?.items);
  const { schedules: yearlyTotalWorkedHours } = calculateHoursTotals(workLogsNew);

  // Im including the holidays in the total worked hours so we can see if we are passing max amount of hours per month
  const hoursWorkedUpToCurrentMonth = yearlyTotalWorkedHours + thisYearHolidays;

  return {
    monthlyExpectedHours: expectedHours.monthlyExpectedHours,
    yearlyExpectedHours: expectedHours.yearlyExpectedHours,
    hoursExpectedUpToCurrentMonth: expectedHours.totalUpToCurrentMonth,
    hoursWorkedUpToCurrentMonth,
  };
};
