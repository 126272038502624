import { Toolbar, AppBar as AppBarMui, Box, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { GlobalSearch } from "../globalSearch/GlobalSearch";
import { useStore } from "../../store/store";
import { useIsMobile } from "../../utils/useIsMobile";
import { NotificationsList } from "../notificationsList/NotificationsList";
import { ProcessesSearchItem } from "../../screens-processes/components/ProcessesSearchItem";

export function AppBar() {
    const location = useLocation();
    const { menu } = useStore();
    const isMobile = useIsMobile();

    if (location.pathname === "/login") return null;

    return (
        <AppBarMui
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "primary.main",
            }}
        >
            <Toolbar sx={{ pl: 5 }}>
                {isMobile ? (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => menu.setIsOpen(!menu.isOpen)}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                ) : null}

                <Box display="flex" alignItems="center" flexGrow={1} justifyContent="end" maxWidth={500} ml="auto">
                    <ProcessesSearchItem />
                    <GlobalSearch />
                    <NotificationsList />
                </Box>
            </Toolbar>
        </AppBarMui>
    );
}
