
import { getStore } from "../../../store/store";
import { AssociateItemFunction } from "../../../types/associate-record.types";

export const associateOrderToTransaction: AssociateItemFunction = async (data, recordContext, _, { updateRecord }) => {
    const { notifications } = getStore();
    if (!recordContext || !updateRecord) return;

    try {
        await updateRecord.mutateAsync({
            id: data.order.id,
            entity: "orders",
            data: {
                bank_transaction: {
                    id: recordContext.id,
                },
            },
        });

        notifications.setNotification({
            isOpen: true,
            message: "Compra associada ao movimento bancário com sucesso",
            severity: "success",
        });
    } catch (error) {
        notifications.setNotification({
            isOpen: true,
            message: "Não foi possível associar a compra ao movimento bancário",
            severity: "error",
        });
    }
};
