import { IconButton } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { useStore } from "../../store/store";

export const ProcessesSearchItem = () => {
    const { processesSearch } = useStore();

    return (
        <IconButton color="inherit" size="large" onClick={() => processesSearch.setIsOpen(true)}>
            <HelpOutline />
        </IconButton>
    );
};
