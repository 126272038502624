import { Box, Typography } from "@mui/material";
import { calculateApiItemsTotal } from "../../orders/utils";
import { formatDate } from "../../../utils/dateUtils";
import { renderFieldView } from "../../../components/fieldsViews/renderFieldView";
import { RelatedRecordsContext } from "../../../components/recordsRelated/RecordsRelated";
import { useContext } from "react";
import { findField } from "../../../utils/findField";
import { formatClientName } from  "./utils"

export type RenderOrderAssociateOptionProps = {
    props: any;
    option: any;
};

export const RenderOrderAssociateOption = ({ props, option }: RenderOrderAssociateOptionProps) => {
    const relatedRecordsContext = useContext(RelatedRecordsContext);
    const paymentField = relatedRecordsContext && findField(relatedRecordsContext.formatedFields, "payment_method");
    const total = calculateApiItemsTotal(option.order_items);
    const clientName = formatClientName(option.treatment.client.user_id.first_name, option.treatment.client.user_id.last_name);

    return (
        <Box {...props} sx={{ display: "flex", gap: 2 }}>
            <Typography variant="inherit">{formatDate(option.order_date)}:</Typography>
            <Typography variant="body1">{option.treatment.client.client_id}</Typography>
            <Typography variant="body1">{clientName}</Typography>
            <Typography variant="body1">{total} €</Typography>
            {paymentField && renderFieldView(paymentField, option, "group")}
        </Box>
    );
};
