import { useCallback } from "react";
import { OrderItem } from "./ordersGridTypes.types";
import { GridValidRowModel } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { ApiRelatedOrderItem } from "./ordersGridTypes.types";
import { GridRowModel } from "@mui/x-data-grid";
import { isToday, parseISO } from "date-fns";
import { groupBy, mapValues } from "lodash";
import { FormatedField } from "../../types/fields.types";

export const calculateRowTotal = (row: OrderItem) => {
  return row.quantity * row.price - row.quantity * row.price * (row.discount / 100);
};

export const useCalculateOrder = () => {
  return useCallback((rows: GridValidRowModel) => {
    return rows.reduce((acumulater: number, current: OrderItem) => {
      return (acumulater = acumulater + calculateRowTotal(current));
    }, 0);
  }, []);
};

export const convertApiItemsToGridRows = (apiItems: ApiRelatedOrderItem[]) => {
  const items = apiItems.map((item: ApiRelatedOrderItem) => {
    const id = uuidv4();

    return {
      id: id,
      order_item_id: item.id,
      variantId: item.service_variant?.id,
      sku: item.service_variant?.sku,
      serviceName: item.service_variant?.service.name,
      agreementName: item.service_variant?.agreement.name,
      type: item.service_variant?.type,
      quantity: item.quantity,
      quantity_used: item.quantity_used,
      price: item.price,
      discount: item.discount,
      alternative_description: item.alternative_description,
    };
  });

  return items as GridRowModel[];
};

export const calculateApiItemsTotal = (apiItems: Array<ApiRelatedOrderItem>) => {
  const total = apiItems.reduce((accumulator: number, item: ApiRelatedOrderItem) => {
    const itemTotal = item.quantity * item.price - item.quantity * item.price * (item.discount / 100);
    return accumulator + itemTotal;
  }, 0);

  return total;
};

export const calculateTotalOfOrders = (orders: Array<any>) => {
  const total = orders.reduce((accumulator, currentOrder) => {
    const orderTotal = calculateApiItemsTotal(currentOrder.order_items);
    const newTotal = accumulator + orderTotal;

    return newTotal;
  }, 0);

  return total;
};

export type GroupedOrdersTotalsByPaymentMethod = {
  [key: string]: number;
};

export const getTodayOrdersGroupedByPaymentMethod = (
  orders: Array<any>,
  paymentMethodField: FormatedField
): GroupedOrdersTotalsByPaymentMethod => {
  // Step 1: Get today's orders
  const todayOrders = orders.filter((order) => isToday(parseISO(order.order_date)));

  // Step 2: Group orders by payment method and calculate totals
  const groupedOrders = groupBy(todayOrders, "payment_method");
  const totalsPerPaymentMethod = mapValues(groupedOrders, calculateTotalOfOrders);

  // Step 3: Create a simple map of payment method values to their display labels
  const paymentMethodLabels: Record<string, string> = {};
  paymentMethodField.settings?.options?.choices?.forEach((choice) => {
    if (choice.value && choice.text) {
      paymentMethodLabels[choice.value] = choice.text;
    }
  });

  // Step 4: Create the final object with readable labels
  const result: GroupedOrdersTotalsByPaymentMethod = {};
  Object.entries(totalsPerPaymentMethod).forEach(([paymentMethod, total]) => {
    if (paymentMethod) {
      const readableLabel = paymentMethodLabels[paymentMethod] || paymentMethod;
      result[readableLabel] = total;
    }
  });

  return result;
};
