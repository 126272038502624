import { RecordContextType } from "../../components/record/Record";
import { leadsSearchSettings, treatmentsSearchSettings } from "../../utils/createSearchFilter";
import { createUrl } from "../../components/calendar/calendarUtils";
import { UseRecordsOptions } from "../../api/useRecords";

export const sessionsOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["end_date", "group_one", "group_two", "group_thre"],
        fieldTypesToRemove: [],
    },

    fieldsWithExtraOptions: [
        {
            field: "treatment",
            createFilter: (inputValue: string) => treatmentsSearchSettings(inputValue),
        },

        {
            field: "status",
            defaultValue: "booked",
        },
        {
            field: "clinic_id",
            defaultValue: { id: 1, label: "Porto" },
        },
        {
            field: "technician",
            sort: "user_id.first_name",
            enabled: true,
        },
        {
            field: "lead",
            createFilter: (inputValue: string) => leadsSearchSettings(inputValue),
        },
        {
            field: "service",
            enabled: true,
            sort: "name",
        },
        {
            field: "start_date",
            dateFormat: "eeee, dd/MM/yyyy HH:mm",
        },
    ],
    createRelatedParams: (params: RecordContextType | undefined) => createSessionParams(params),
    fetch: {
        fetchInterval: 1000,
    },
    navigateAfterCreate: (params: any) => createUrl(params, "sessions"),
    navigateAfterUpdate: (params: any) => createUrl(params, "sessions"),
    extraOptions: {
        sort: "-start_date",
    },
};

const createSessionParams = async (recordContext: RecordContextType | undefined) => {
    if (!recordContext?.item) return;

    if (recordContext.entity === "treatments")
        return `[treatments][id][*.*,client.user_id.*]=${recordContext.item.id}&[clinics][id]=1&[clients][id]=${recordContext.item.client.id}`;

    if (recordContext.entity === "clients") return `[clients][id]=${recordContext.item.id}&[clinics][id]=1`;
};
