import { Controller } from "react-hook-form";
import { TextField, Box } from "@mui/material";
import { FieldProps } from "../../renderFieldInput";
import { SelectTemplate } from "../../../selectTemplate/SelectTemplate";

interface FeedbackTitleProps extends FieldProps {
    value: {
        title: string;
    };
}
export const FeedbackTtitleInputField = ({ field, value, entityForm }: FeedbackTitleProps) => {
    return (
        <Box sx={{ display: "flex", gap: 3 }}>
            <Box sx={{ flex: 2 }}>
                <Controller
                    name="title"
                    control={entityForm.control}
                    defaultValue={value?.title || ""}
                    render={({ field }) => (
                        <TextField {...field} fullWidth type="text" label="Título de feedback" error={false} />
                    )}
                />
            </Box>
            <Box sx={{ flex: 1 }}>
                <SelectTemplate entityForm={entityForm} field={field.field} entity={field.settings?.entity || ""} />
            </Box>
        </Box>
    );
};
