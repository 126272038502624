import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { RecordsContextType } from "../records/Records";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";

type FilterFieldProps = {
    index: number;
    entityForm: UseFormReturn;
    formErrors: any[];
    context: RecordsContextType;
};

export const FilterField = ({ index, entityForm, formErrors, context }: FilterFieldProps) => {
    const fields = context?.formatedFields;
    const extraOptions = context?.options?.groupsOptions?.fieldsWithExtraOptions;

    if (!fields) return null;

    return (
        <Controller
            name={`filters.${index}.field`}
            control={entityForm.control}
            defaultValue={null}
            rules={{ required: "Este campo não pode estar vazio" }}
            render={({ field: inputField }) => (
                <FormControl fullWidth error={formErrors[index]?.field ? true : false}>
                    <InputLabel>Campo</InputLabel>
                    <Select
                        {...inputField}
                        label="Campo"
                        fullWidth
                        onChange={(e) => {
                            const selectedFieldObj = fields.find((field) => field.field === e.target.value);
                            const fieldExtraOptions =
                                extraOptions && selectedFieldObj && getFieldExtraOption(extraOptions, selectedFieldObj);

                            if (selectedFieldObj && !selectedFieldObj?.settings.relatedTable) {
                                selectedFieldObj.settings = {
                                    ...selectedFieldObj?.settings,
                                    relatedTable: fieldExtraOptions?.relatedTable || "",
                                };
                            }

                            inputField.onChange(selectedFieldObj);
                        }}
                        value={inputField.value.field || ""}
                    >
                        {fields.map((field) => {
                            const fieldsToExclueByName = ["user", "new_tab", "preview"];
                            const excludeByInterface = ["group-raw", "list-o2m"];

                            if (
                                !fieldsToExclueByName.includes(field.field) &&
                                !excludeByInterface.includes(field.settings?.interface || "")
                            ) {
                                return (
                                    <MenuItem key={field.field} value={field.field}>
                                        {field.headerName}
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                    </Select>
                    <FormHelperText>{formErrors[index]?.field?.message}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
