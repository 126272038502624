import {
    Dialog,
    DialogTitle,
    Button,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    FormControl,
    InputLabel,
} from "@mui/material";
import { useStore } from "../../store/store";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";

export const OrdersDialog = () => {
    const { order, notifications } = useStore();
    const [isLoading, setIsLoading] = useState(false);

    const entityForm = useForm({
        defaultValues: {
            invoice_id: "",
            document_type: "invoiceReceipts",
        },
    });

    const handleSend = async (formData: { invoice_id: string; document_type: string }) => {
        if (!formData.invoice_id) {
            alert("Preencha o campo do invoice id");
            return;
        }
        notifications.setNotification({
            isOpen: false,
        });
        setIsLoading(true);

        try {
            await axios({
                method: "POST",
                url: "https://hook.eu1.make.com/c8cnb82c5nsexni019o34vyijbgq70gh",
                data: {
                    invoice_id: formData.invoice_id,
                    order_id: order.context.item.id,
                    document_type: formData.document_type,
                },
            });

            notifications.setNotification({
                isOpen: true,
                message: `Fatura associada com sucesso`,
                severity: "success",
            });

            entityForm.reset();
            order.setIsOpen(false);
        } catch (error: any) {
            console.log(error);
            const errorMessage = error?.response?.data || "Erro ao associar fatura";
            notifications.setNotification({
                isOpen: true,
                message: errorMessage,
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={order.isOpen} onClose={() => order.setIsOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Associar fatura manualmente</DialogTitle>
            <form onSubmit={entityForm.handleSubmit(handleSend)}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Controller
                        name="document_type"
                        control={entityForm.control}
                        render={({ field: inputField }) => {
                            return (
                                <FormControl fullWidth>
                                    <InputLabel>Tipo de documento</InputLabel>
                                    <Select
                                        {...inputField}
                                        onChange={(event: SelectChangeEvent<string>) => {
                                            inputField.onChange(event.target.value);
                                        }}
                                        autoFocus
                                        margin="dense"
                                        label="Tipo de documento"
                                        fullWidth
                                    >
                                        <MenuItem value="invoiceReceipts">Fatura/Recibo</MenuItem>
                                        <MenuItem value="creditNotes">Nota de Crédito</MenuItem>
                                    </Select>
                                </FormControl>
                            );
                        }}
                    />
                    <Controller
                        name="invoice_id"
                        control={entityForm.control}
                        render={({ field }) => <TextField {...field} autoFocus margin="dense" label="Invoice ID" fullWidth />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => order.setIsOpen(false)}>
                        Cancelar
                    </Button>
                    <Button type="submit">{isLoading ? <CircularProgress color="inherit" size={24} /> : "Associar"}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
