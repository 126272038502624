import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../../../store/store";


export const useStaffWorkLogsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth } = useStore((state) => state.staffWorkLogs);

  useEffect(() => {
    const yearParam = searchParams.get("year");
    const monthParam = searchParams.get("month");

    if (yearParam) {
      setSelectedYear(parseInt(yearParam));
    }
    if (monthParam) {
      setSelectedMonth(parseInt(monthParam) - 1); // Subtract 1 since months are 0-based in JS
    }
  }, [searchParams, setSelectedYear, setSelectedMonth]);

  useEffect(() => {
    setSearchParams({
      year: selectedYear.toString(),
      month: (selectedMonth + 1).toString(), // Add 1 to match human-readable months (1-12)
    });
  }, [selectedYear, selectedMonth, setSearchParams]);

  return { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth };
};

export const filterEmployeeSummaries = (employeeSummaries: any[], selectedAgreementType: string) => {
  return employeeSummaries.filter((summary) => {
    const agreementType = summary.payment?.staff_agreement?.agreement_type;
    if (selectedAgreementType === "all") return true;
    if (selectedAgreementType === "services_provider" && agreementType === "services_provider") return true;
    if (selectedAgreementType === "contracts" && ["full_term", "term_contract"].includes(agreementType)) return true;
    return false;
  });
};
