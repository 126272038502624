import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { TreatmentData } from "../../../../types/fields.types";
import { parse } from "date-fns";
import { formatDate } from "../../../../utils/dateUtils";
import { useField } from "../../../../api/useField";

type TreatmentProvidedFieldType = {
    field: string;
    meta: {
        type: string;
        interface: string;
        options: {
            choices: Array<{
                text: string;
                value: string;
            }>;
        };
    };
};

type TreatmentProvidedFieldProps = {
    fieldView: string;
    value:
        | {
              treatment_provided: Array<TreatmentData>;
              sessions: Array<Session>;
          }
        | {
              row: {
                  treatment_provided: Array<TreatmentData>;
                  sessions: Array<Session>;
              };
          };
};

export function TreatmentProvidedField({ value, fieldView }: TreatmentProvidedFieldProps) {
    let valueTreatmentProvided = null;

    const { formatedField: field } = useField("treatments", "treatment_provided");

    //@ts-ignore
    const sessions = value?.sessions || value?.row?.sessions;
    const sessionsDates = sessions ? getSessionDates(sessions) : null;

    if (value && typeof value === "object" && "treatment_provided" in value) {
        valueTreatmentProvided = value.treatment_provided;
    } else if (value && typeof value.row === "object" && "row" in value && "treatment_provided" in value.row) {
        valueTreatmentProvided = value.row.treatment_provided;
    }
    if (!valueTreatmentProvided || !field) return null;

    if (fieldView !== "grid") {
        return (
            <TableContainer component={Paper} variant="outlined">
                <Table>
                    <TableBody>
                        {valueTreatmentProvided.map((item: any) => {
                            const treatmentName =
                                field.settings &&
                                getLabel(item, field.settings.options?.fields as Array<TreatmentProvidedFieldType>, "treatment");
                            const status =
                                field.settings &&
                                getLabel(item, field.settings.options?.fields as Array<TreatmentProvidedFieldType>, "status");
                            const dates = sessionsDates && sessionsDates[item.treatment as keyof SessionsDates];
                            const lastSessionBooked =
                                sessionsDates && sessionsDates[item.treatment as keyof SessionsDates]?.latestBookedSession;

                            return (
                                <TableRow key={item.treatment}>
                                    <TableCell sx={{ width: "30%", p: 3 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                            {treatmentName}
                                        </Typography>
                                        <Typography variant="body2">{status}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ p: 3 }}>
                                        {dates && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 1,
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    <strong>Primeira Realizada:</strong> {dates.oldestDate || "—"}
                                                </Typography>

                                                <Typography variant="body2">
                                                    <strong>Última Realizada:</strong> {dates.mostRecentDate || "—"}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color={
                                                        isLastSessionBookedWrong(lastSessionBooked, item.status)
                                                            ? "error"
                                                            : "inherit"
                                                    }
                                                >
                                                    <strong>Agendada:</strong> {lastSessionBooked || "—"}
                                                </Typography>
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <Box display="flex" columnGap={4} alignItems="center">
                {valueTreatmentProvided.map((item: any) => {
                    const treatmentName =
                        field.settings &&
                        getLabel(item, field.settings.options?.fields as Array<TreatmentProvidedFieldType>, "treatment");
                    const status =
                        field.settings &&
                        getLabel(item, field.settings.options?.fields as Array<TreatmentProvidedFieldType>, "status");

                    const lastSessionBooked =
                        sessionsDates && sessionsDates[item.treatment as keyof SessionsDates]?.latestBookedSession;
                    const lastSessionDone = sessionsDates && sessionsDates[item.treatment as keyof SessionsDates]?.mostRecentDate;

                    return (
                        <Box
                            key={item.treatment}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                pl: 5,
                                pr: 5,

                                borderRadius: 10,
                                backgroundColor: "grey.100",
                            }}
                        >
                            <Typography variant="body2">
                                <strong>{treatmentName}</strong>: {status}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                <Typography variant="body2">
                                    <strong>Última:</strong> {lastSessionDone || "—"}
                                </Typography>
                                <Divider orientation="vertical" flexItem />
                                <Typography
                                    variant="body2"
                                    color={isLastSessionBookedWrong(lastSessionBooked, item.status) ? "error" : "inherit"}
                                >
                                    <strong>Agendada:</strong> {lastSessionBooked || "—"}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );
    }
}

const getLabel = (fieldValue: TreatmentData, fieldsConfig: Array<TreatmentProvidedFieldType>, fieldName: string) => {
    const result = {} as TreatmentData;

    Object.keys(fieldValue).forEach((key) => {
        const fieldConfig = fieldsConfig.find((config) => config.field === key);
        if (fieldConfig) {
            const option = fieldConfig.meta.options.choices.find(
                (choice: { text: string; value: string }) => choice.value === fieldValue[key as keyof TreatmentData]
            );
            if (option) {
                result[key as keyof TreatmentData] = option.text;
            }
        }
    });

    return result[fieldName as keyof TreatmentData];
};

type Session = {
    service: number;
    status: string;
    start_date: string;
};

type SessionsDates = {
    psychology: {
        mostRecentDate: string | null;
        oldestDate: string | null;
        latestBookedSession: string | null;
    };
    neurofeedback: {
        mostRecentDate: string | null;
        oldestDate: string | null;
        latestBookedSession: string | null;
    };
};

function getSessionDates(sessions: Array<Session>) {
    const VALID_SERVICES = [33, 34];

    const sessionsDates = {
        psychology: { mostRecentDate: "", oldestDate: "", latestBookedSession: "" },
        neurofeedback: {
            mostRecentDate: "",
            oldestDate: "",
            latestBookedSession: "",
        },
    } as SessionsDates;

    sessions.forEach((session) => {
        if (!VALID_SERVICES.includes(session.service)) return;

        const sessionDate = session.start_date ? new Date(session.start_date) : null;
        const sessionType = session.service === 34 ? "psychology" : "neurofeedback";

        if (sessionType && sessionDate) {
            const currentDates = sessionsDates[sessionType];

            if (session.status === "done") {
                if (!currentDates.mostRecentDate || sessionDate > new Date(currentDates.mostRecentDate)) {
                    sessionsDates[sessionType].mostRecentDate = session.start_date;
                }
                if (!currentDates.oldestDate || sessionDate < new Date(currentDates.oldestDate)) {
                    sessionsDates[sessionType].oldestDate = session.start_date;
                }
            }

            if (session.status === "booked" || session.status === "confirmed") {
                if (!currentDates.latestBookedSession || sessionDate > new Date(currentDates.latestBookedSession)) {
                    sessionsDates[sessionType].latestBookedSession = session.start_date;
                }
            }
        }
    });

    return {
        psychology: {
            mostRecentDate: formatDate(sessionsDates.psychology.mostRecentDate),
            oldestDate: formatDate(sessionsDates.psychology.oldestDate),
            latestBookedSession: formatDate(sessionsDates.psychology.latestBookedSession),
        },
        neurofeedback: {
            mostRecentDate: formatDate(sessionsDates.neurofeedback.mostRecentDate),
            oldestDate: formatDate(sessionsDates.neurofeedback.oldestDate),
            latestBookedSession: formatDate(sessionsDates.neurofeedback.latestBookedSession),
        },
    } as SessionsDates;
}

const isLastSessionBookedWrong = (lastBookedSession: string | null, status: string): boolean => {
    if (status !== "ongoing") return false;

    if (!lastBookedSession) return true;

    const parsedDate = parse(lastBookedSession, "dd/MM/yyyy", new Date());
    const today = new Date();

    if (parsedDate > today) return false;

    return true;
};
