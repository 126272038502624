import { getChipLabel } from "../../../components/chips/CustomChip";
import { queryClient } from "../../../main";
import { formatDate } from "../../../utils/dateUtils";
import { findField } from "../../../utils/findField";
import { calculateApiItemsTotal } from "../../orders/utils";
import { formatClientName } from "./utils";

export const renderOrderSelectedOption = (option: any) => {
    const total = calculateApiItemsTotal(option.order_items);
    const fields = queryClient.getQueryData(["orders-fields"]) as any;
    const paymentField = findField(fields, "payment_method");
    const clientName = formatClientName(option.treatment.client.user_id.first_name, option.treatment.client.user_id.last_name);
    paymentField.settings = paymentField.meta;
    const paymentFieldLabel = getChipLabel(paymentField, option.payment_method);

    return `${formatDate(option.order_date)}: ${option.treatment.client.client_id} ${clientName} - ${total}€ (${paymentFieldLabel})`;
};
