import { Chip } from "@mui/material";
import {  FormatedField } from "../../types/fields.types";

export type CustomChipProps = {
    field: FormatedField;
    label: string;
    extraOptions?: ChipExtraOption;
};

export type ChipExtraOption = {
    style?: {
        size: "small" | "medium";
    };
};

export const CustomChip = ({ field, label, extraOptions }: CustomChipProps) => {

    return (
        <Chip
            label={getChipLabel(field, label)}
            sx={{
                backgroundColor: getChipColor(field, label)?.background,
                color: getChipColor(field, label)?.foreground,
            }}
            size={extraOptions?.style?.size || "medium"}
        />
    );
};

export function getChipLabel(field: FormatedField, value: string) {
    return field.settings?.options?.choices?.find((choice) => choice.value === value)?.text;
}

function getChipColor(field: FormatedField, value: string) {
    return field.settings?.display_options?.choices?.find((choice) => choice.value === value);
}
