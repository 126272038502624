import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "./config";
import { ErrorResponsesCreateOrUpdate, Record } from "../types/records.types";
import { MutationOptions } from "../types/mutation.types";

export const useUpdateRecord = (options?: MutationOptions) => {
    const queryClient = useQueryClient();
    return useMutation<any, ErrorResponsesCreateOrUpdate, Record, any>({
        mutationFn: (record: Record) => updateRecord(record),
        onSuccess(data, variables) {
            queryClient.invalidateQueries({
                predicate(query: any) {
                    return query.queryKey[0].includes(options?.queryInvalidatePrefix || `${variables.entity}-records`);
                },
            });
            queryClient.invalidateQueries({ queryKey: [`${variables.entity}-${data?.id || variables.id}`] });
        },
        onError(error: ErrorResponsesCreateOrUpdate) {
            return error.response.data.errors[0].message;
        },
        
    });
};

export const updateRecord = async (record: Record) => {
    const data = record.data;

    const updatedRecord = await api({
        method: "PATCH",
        url: `items/${record.entity}/${record.id}`,
        data: data,
    });

    if (record.entity === "clients") {
        const userFields = extractUserFields(data);

        if (Object.keys(userFields).length > 0) {
            const userId = record.recordContext?.item.user_id?.id;

            await api({
                method: "PATCH",
                url: `/users/${userId}`,
                data: userFields,
            });
        }
    }

    return updatedRecord.data.data;
};

const extractUserFields = (data: { [key: string]: any }): { [key: string]: any } => {
    const result: { [key: string]: any } = {};
    const prefix = "user_";

    for (const key in data) {
        if (key.startsWith(prefix)) {
            const newKey = key.replace(prefix, "");
            result[newKey] = data[key];
        }
    }

    return result;
};
