import currency from "currency.js";

export const formatCurrency = (value: number, decimalSeparator?: string) => {
    return currency(value, {
        symbol: "€",
        decimal: decimalSeparator || ",",
        separator: " ",
        precision: 2,
    }).format();
};
