import { useContext, useEffect, useState, createContext } from "react";
import { DataGrid } from "../datagrid/DataGrid";
import { useRelatedRecords } from "../../api/useRelatedRecords";
import { RecordContext } from "../record/Record";
import { CircularProgress, Button, Unstable_Grid2 as Grid, Box } from "@mui/material";
import { UseRecordsOptions } from "../../api/useRecords";
import { Link } from "react-router-dom";
import { useFields } from "../../api/useFields";
import { RecordsRelatedCustomFilterParamsType } from "../../utils/recordsRelatedCustomFilter";
import { RecordsContextType } from "../records/Records";
import { useCollection } from "../../api/useCollection";
import { useStore } from "../../store/store";

type RecordsRelatedProps = {
    entity: string;
    fieldsToQuery: string;
    fieldToFilter?: string;
    recordsOptions?: UseRecordsOptions;
    customFilter?: (params: RecordsRelatedCustomFilterParamsType) => any;
    heightOfHeaders?: number;
    customComponentHeader?: React.ReactNode;
    customComponentContent?: React.ReactNode;
};

export const RelatedRecordsContext = createContext<RecordsContextType | null>(null);

export const RecordsRelated = ({
    entity,
    fieldsToQuery,
    fieldToFilter,
    recordsOptions,
    customFilter,
    heightOfHeaders = 398,
    customComponentHeader,
    customComponentContent,
}: RecordsRelatedProps) => {
    const recordContext = useContext(RecordContext);
    const { formatedFields, status: statusFields } = useFields(entity, recordsOptions);
    const [recordsSort, paginationModel] = useStore((state) => {
        if (recordsOptions?.extraOptions?.sort && (!state.recordsSort || !state.recordsSort[entity])) {
            state.setRecordsSort({ [entity]: { sort: recordsOptions.extraOptions.sort } });
        }

        return [state.recordsSort, state.gridSettings.paginationModel];
    });

    const { data, status, refetch } = useRelatedRecords(
        entity,
        fieldsToQuery,
        fieldToFilter,
        recordContext?.id,
        customFilter && customFilter({ parentId: recordContext?.id, collection: recordContext?.entity }),
        {
            extraOptions: {
                page: paginationModel.page,
                pageSize: paginationModel.pageSize,
                ...recordsOptions?.extraOptions,
                sort: recordsSort?.[entity]?.sort,
            },
        }
    );
    const [relatedParams, setRelatedParams] = useState<string>(``);

    useEffect(() => {
        if (recordContext && recordsOptions?.createRelatedParams) {
            recordsOptions?.createRelatedParams(recordContext).then((res) => {
                if (res) {
                    setRelatedParams(res);
                }
            });
        }
    }, [recordContext, recordsOptions]);
    const { data: collection, status: statusCollection } = useCollection(entity);

    if (status === "loading" || statusFields === "loading" || statusCollection === "loading")
        return <CircularProgress sx={{ marginTop: 5 }} color="secondary" />;
    if (status === "error" || statusFields === "error") return <div>error</div>;

    const recordsContext = {
        ...data,
        relatedEntity: entity,
        formatedFields,
        collection,
        type: "list" as "list",
        dataGridOptions: recordsOptions?.dataGridOptions,
        metadata: {
            total_count: data.metadata?.total_count,
            filter_count: data.metadata?.filter_count,
        },
        paginationModel,
        refetch,
    };

    const hideCreationButton = recordsOptions?.relatedRecords?.hideCreationButton;

    return (
        <RelatedRecordsContext.Provider value={recordsContext}>
            <Grid container sx={{ backgroundColor: "#fff" }}>
                <Grid xs={12} mr={4} mb={4} mt={4} display="flex" justifyContent="flex-end" gap={2}>
                    {customComponentHeader}
                    {hideCreationButton ? null : (
                        <Button component={Link} to={`/${entity}/create?${relatedParams}`}>
                            Criar
                        </Button>
                    )}
                </Grid>
                <Grid xs={12}>
                    {customComponentContent || (
                        <DataGrid paginationModel={paginationModel} heightOfHeaders={heightOfHeaders} type="relatedRecords" />
                    )}
                </Grid>
            </Grid>
        </RelatedRecordsContext.Provider>
    );
};
