import { useContext } from "react";
import { RecordsHeader } from "../../components/recordsHeader/RecordsHeader";
import { RecordsContext } from "../../components/records/Records";
import { Box, Typography } from "@mui/material";
import { useRecords } from "../../api/useRecords";
import { formatCurrency } from "../../utils/formatCurrency";
import { BANK_TRANSACTIONS_SINCE_DATE, ORDERS_SINCE_DATE } from "../../utils/constants";
import { calculateTotalOfOrders, GroupedOrdersTotalsByPaymentMethod } from "./utils";
import { calculateTransactionsTotalAmount } from "../bank-transactions/OrdersAssociatedRecords/utils";

import { getTodayOrdersGroupedByPaymentMethod } from "./utils";
import { findField } from "../../utils/findField";

export const OrdersListHeader = () => {
  const recordsContext = useContext(RecordsContext);

  const { data: bankTransactions, status: bankTransactionsStatus } = useRecords("bank_transactions", "*.*", {
    extraOptions: {
      filter: {
        _and: [{ bank: { _eq: 8 } }, { transaction_date: { _gte: BANK_TRANSACTIONS_SINCE_DATE } }],
      },
    },
  });

  const { data: moneyOrders, status: moneyOrdersStatus } = useRecords("orders", "*.*", {
    extraOptions: {
      filter: {
        _and: [
          { payment_method: { _eq: "cash" } },
          { order_date: { _gte: ORDERS_SINCE_DATE } },
          { bank_transaction: { _null: true } },
        ],
      },
    },
  });

  if (
    !recordsContext ||
    bankTransactionsStatus !== "success" ||
    moneyOrdersStatus !== "success" ||
    !recordsContext.items
  )
    return null;
  const paymentMethodField = findField(recordsContext.formatedFields, "payment_method");
  const groupedTodayOrders = getTodayOrdersGroupedByPaymentMethod(recordsContext.items, paymentMethodField);
  const moneyOrdersTotal = calculateTotalOfOrders(moneyOrders?.items);

  const totalAmountTransactions = calculateTransactionsTotalAmount(bankTransactions?.items);
  const totalPocketMoney = moneyOrdersTotal + totalAmountTransactions;

  return (
    <Box>
      <RecordsHeader
        collection={recordsContext?.collection}
        optionalComponents={{
          leftOfButtons: (
            <Box sx={{ display: "flex", gap: 10, alignItems: "flex-end" }}>
              <PaymentMethodTotals groupedOrders={groupedTodayOrders} />
              <PocketMoney totalAmount={totalPocketMoney}  />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

type PocketMoneyProps = {
  totalAmount: number;
};

const PocketMoney = ({ totalAmount }: PocketMoneyProps) => {
  return (
    <Box sx={{ display: "flex", gap: 5, mr: 5 }}>
      <Box>
        <Typography variant="body1" fontWeight={600}>
          {formatCurrency(totalAmount)}
        </Typography>
        <Typography variant="body2">Fundo de caixa</Typography>
      </Box>
    </Box>
  );
};

type PaymentMethodTotalsProps = {
  groupedOrders: GroupedOrdersTotalsByPaymentMethod;
};

const PaymentMethodTotals = ({ groupedOrders }: PaymentMethodTotalsProps) => {
  return (
    <Box>
      <Typography variant="body2" color="primary">
        Resumo do dia de hoje:
      </Typography>
      <Box sx={{ display: "flex", gap: 4 }}>
        {Object.entries(groupedOrders).map(([paymentMethod, total]) => (
          <Box key={paymentMethod}>
            <Typography variant="body1" fontWeight={600}>
              {formatCurrency(Number(total))}
            </Typography>
            <Typography variant="body2">{paymentMethod}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
