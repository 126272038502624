import { format } from "date-fns";

export function getValueByDotNotation(obj: any, dotNotation: string) {
    const properties = dotNotation.split(".");
    let currentValue = obj;

    for (const property of properties) {
        if (currentValue?.hasOwnProperty(property)) {
            currentValue = currentValue[property] ? currentValue[property] : "";
        } else {
            return undefined;
        }
    }

    return currentValue;
}

const getValuesBetweenBracers = (displayTemplate: string) => {
    const regex = /{{([^}]+)}}|([^{}]+)/g;
    let match;
    const values = [];

    while ((match = regex.exec(displayTemplate)) !== null) {
        if (match[1]) {
            values.push({ type: "inside", value: match[1] });
        } else if (match[2]) {
            values.push({ type: "outside", value: match[2] });
        }
    }
    return values;
};

export const handleLabel = (obj: any, displayTemplate: string | undefined, extraOptions?: any) => {
    if (!obj) return "";
    if (!displayTemplate) return "no template defined";

    const values = getValuesBetweenBracers(displayTemplate);

    let fieldValue = "";

    values.forEach((value) => {
        if (value.type === "inside") {
            fieldValue += getValueByDotNotation(obj, value.value);
        } else if (value.type === "outside" && fieldValue) {
            fieldValue += value.value;
        }
    });

    // in cas of being a date it properly format it
    const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/;
    fieldValue = fieldValue.replace(dateRegex, (match) => format(new Date(match), "dd-MM-yyyy HH:mm"));

    if (extraOptions?.charactersLimit) {
        fieldValue = fieldValue.substring(0, extraOptions.charactersLimit) + "...";
    }
    return fieldValue;
};
