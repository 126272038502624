import { MenuItem } from "../components/menu/MenuItems";
import { Collection } from "../types/collections.types";
import { UserFromAPI } from "../types/auth.types";
import { ROLES } from "./constants";

export const getMenuItems = (data: Collection[], formatedMenuItems: MenuItem[], user: UserFromAPI) => {
    const exclusionsByRole = [
        {
            collection: "sessions",
            role: ROLES.accounting,
        },
        {
            collection: "orders",
            role: ROLES.accounting,
        },
        {
            collection: "schedules",
            role: ROLES.accounting,
        },
        {
            collection: "bank_transactions",
            role: ROLES.recepcionist,
        },
        {
            collection: "orders",
            role: ROLES.virtual_assistant,
        },
    ];

    const addEvenIfNoCollection = ["staff-work-logs"];

    return formatedMenuItems.filter(
        (formatedItem) =>
            (data.some((menuItem: Collection) => menuItem.collection === formatedItem.collection) ||
                addEvenIfNoCollection.includes(formatedItem.collection)) &&
            !exclusionsByRole.some(
                (exclusion) => exclusion.collection === formatedItem.collection && exclusion.role.includes(user.role.id)
            )
    );
};
