import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./bankTransactionsTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { bankTransactionsOptions } from "./bankTransactionsOptions";
import { BankTransactionsListHeader } from "./BankTransactionsListHeader";
import { RecordsRelated } from "../../components/recordsRelated/RecordsRelated";
import { ordersOptions } from "../orders/ordersOptions";
import { BankTransactionDetailHeader } from "./BankTransactionDetailHeader";
import { OrderAssociateComponent } from "./OrdersAssociatedRecords/OrderAssociateComponent";

const fieldsQuery = "*.*,category.*.*,orders.order_items.*";

export const bankTransactionsRoutes = [
    {
        path: "/bank_transactions",
        element: (
            <Records
                entity="bank_transactions"
                header={<BankTransactionsListHeader />}
                fieldsQuery={fieldsQuery}
                options={bankTransactionsOptions}
                heightOfHeaders={296}
            />
        ),
    },
    {
        path: "/bank_transactions/:id",
        element: (
            <Record
                entity="bank_transactions"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<BankTransactionDetailHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/bank_transactions/:id/detail",
                element: <Groups groupsOptions={bankTransactionsOptions.groupsOptions} />,
            },
            {
                element: (
                    <RecordsRelated
                        entity="orders"
                        fieldsToQuery="*.*,treatment.client.*,treatment.client.user_id.*"
                        customFilter={(params) => {
                            return {
                                bank_transaction: {
                                    id: {
                                        _eq: params.parentId,
                                    },
                                },
                            };
                        }}
                        recordsOptions={ordersOptions}
                        customComponentHeader={<OrderAssociateComponent />}
                    />
                ),
                path: "/bank_transactions/:id/orders",
            },
        ],
    },
    {
        path: "/bank_transactions/:id/update",
        element: (
            <Record
                entity="bank_transactions"
                type="update"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={bankTransactionsOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/bank_transactions/create",
        element: (
            <Record
                entity="bank_transactions"
                type="create"
                fieldsQuery={fieldsQuery}
                body={<EntityForm groupsOptions={bankTransactionsOptions?.groupsOptions} />}
            />
        ),
    },
];
