import { Select, MenuItem, CircularProgress, FormControl, InputLabel } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { useRecords } from "../../../api/useRecords";

type ClinicsDropdownProps = {
    entityForm: UseFormReturn;
    fieldName?: string;
    value?: string;
};

export const ClinicsDropdown = ({ entityForm, fieldName = "clinic", value }: ClinicsDropdownProps) => {
    const { data: clinics, status: clinicsStatus } = useRecords("clinics", "*.*", { staleTime: Infinity });

    if (clinicsStatus === "loading") return <CircularProgress />;
    if (clinicsStatus === "error") return <div>error...</div>;

 

    return (
        <Controller
            name={fieldName}
            control={entityForm.control}
            defaultValue={value || ""}
            render={({ field }) => (
                <FormControl fullWidth>
                    <InputLabel>Clínica</InputLabel>
                    <Select
                        {...field}
                        fullWidth
                        onChange={(event) => field.onChange(event.target.value)}
                        sx={{ maxHeight: "56px" }}
                        label="Clínica"
                    >
                        <MenuItem value="" disabled>
                            Selecionar Clínica
                        </MenuItem>
                        {clinics?.items.map((clinic: any) => (
                            <MenuItem key={clinic.id} value={clinic.id}>
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    );
};
